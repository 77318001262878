import React from 'react';
import {Link} from 'react-router-dom';
import CommonPageLayout from 'components/page-layout/CommonPageLayout';

const NotFound = () => (
    <CommonPageLayout className="main-landing-page__component">
        <div class="landing-page-content__component">
            <div class="header-container">
                <h1>404 Page Not Found</h1>
                <div class="description">
                    Sorry! We could'nt find the page you where looking for.
                    <br/><br/>
                    <Link to="/">Return to homepage</Link>
                </div>  
            </div>
        </div>
  </CommonPageLayout>
);

export default NotFound;
