import React, {Component} from "react";
import {Button, Modal} from "semantic-ui-react";
import {createBrowserHistory} from "history";
import PropTypes from "prop-types";

import TagManagerResHelpContent from "./HelpContentFiles/TagManagerResHelpContent.json";
import TagManagerRgHelpContent from "./HelpContentFiles/TagManagerRgHelp.json";
import AfpHomePageHelp from "./HelpContentFiles/AfpHomePageHelp.json";
import TemplateLibraryHelp from "./HelpContentFiles/TemplateLibraryHelp.json";
import HamburgerMenu from "./HelpHamburgerMenu/index";
import OutsideClickHandler from "react-outside-click-handler";
class HelpModal extends Component {
	state = {
		openNestedModal: false,
		content: {},
		nestedContent: [],
		nextPageValue: "",
		allPages: {},
		openModal: false,
		showPopup: false,
		popupHelpActive: false
	};

	static propTypes = {
		close: PropTypes.func,
		openHelp: PropTypes.func
	};
	open = (page) => {
		this.close(this.state.content);
		if (this.state[page] !== true) {
			this.setState({
				[page]: true
			});
		}
	};
	close = (content, dontClose) => {
		content.pages.forEach((page, index) => {
			if (this.state["page" + index] !== false) {
				this.setState({["page" + index]: false});
			}
		});
		if (dontClose !== true) {
			this.props.close();
		}
	};

	getPage = () => {
		const history = createBrowserHistory();

		return history.location.pathname;
	};

	componentDidUpdate() {
		const page = this.getPage();
		if (page === "/tag-manager") {
			if (this.props.activeTab === "resource-group" && this.state.content !== TagManagerRgHelpContent) {
				this.setState(
					{
						content: TagManagerRgHelpContent
					},
					() => {
						this.setState({
							nestedContent: this.buildContent()
						});
					}
				);
			} else {
				if (this.props.activeTab === "resources" && this.state.content !== TagManagerResHelpContent) {
					this.setState(
						{
							content: TagManagerResHelpContent
						},
						() => {
							this.setState({
								nestedContent: this.buildContent()
							});
						}
					);
				}
			}
		}
	}
	componentDidMount() {
		const page = this.getPage();
		if (page === "/tag-manager") {
			if (this.props.activeTab === "resource-group") {
				this.setState(
					{
						content: TagManagerRgHelpContent
					},
					() => {
						this.setState({
							nestedContent: this.buildContent()
						});
					}
				);
			} else {
				if (this.props.activeTab === "resources") {
					this.setState(
						{
							content: TagManagerResHelpContent
						},
						() => {
							this.setState({
								nestedContent: this.buildContent()
							});
						}
					);
				}
			}
		}
		if (page === "/") {
			this.setState(
				{
					content: AfpHomePageHelp
				},
				() => {
					this.setState({
						nestedContent: this.buildContent()
					});
				}
			);
		}
		if (page === "/template-library") {
			this.setState(
				{
					content: TemplateLibraryHelp
				},
				() => {
					this.setState({
						nestedContent: this.buildContent()
					});
				}
			);
		}
	}

	contentLines = (lines) => {
		if (lines !== undefined && lines.length > 0) {
			let textLines = [];
			lines.map((line, index) => {
				if (line.substring(0, 5) !== "image") {
					textLines.push(<p key={index + "text"}>{line}</p>);
				} else {
					textLines.push(<img key={index + "img"} src={require("./Images/" + line.substring(5))} />);
				}
			});
			return textLines;
		}
	};

	togglePopup = () => {
		this.setState({
			showPopup: !this.state.showPopup
		});
	};

	handleClickOutside = (e) => {
		if (e.target.id !== "icon") {
			this.setState({showPopup: false});
		}
	};

	buildMenu = (content) => {
		let pageLinks = [];

		content.pages.forEach((page, index) => {
			pageLinks.push(
				<button
					onClick={() => {
						if (index === 0) {
							this.close(content, true);
							this.props.openHelp();
						} else {
							this.open("page" + (index - 1));
						}
						this.togglePopup();
					}}>
					{page.title}
				</button>
			);
		});
		return pageLinks;
	};
	buildContent = () => {
		let fullContent = [];
		let pages = [];
		const content = this.state.content;
		if (content && content.pages) {
			content.pages.map((page, index) => {
				if (index !== 0) {
					const curPage = "page" + (index - 1);
					let isLast = false;
					if (content.pages.length === index + 1) {
						isLast = true;
					}
					fullContent.push(
						<Modal key={curPage} open={this.state[curPage]} onClose={() => this.close(content)}>
							{this.state.showPopup && (
								<OutsideClickHandler onOutsideClick={() => this.handleClickOutside()}>
									<div className="popup-help-container">{this.buildMenu(content)}</div>
								</OutsideClickHandler>
							)}
							<Modal.Header style={modalHeaderStyle}>
								<p className="help-modal-title">{page.title}</p>
								<HamburgerMenu togglePopup={() => this.togglePopup()} showPopup={this.state.showPopup} />
							</Modal.Header>
							<Modal.Content style={modalContentStyle}>
								<Modal.Description style={modalDescriptionStyle}>
									{this.contentLines(content.pages[index].contentLines)}
								</Modal.Description>
							</Modal.Content>
							<Modal.Actions style={modalActionsStyle}>
								{isLast ? (
									<Button style={btnProceedStyle} content="Done" onClick={() => this.close(content)} />
								) : (
									this.state.nextPageValue
								)}
								<Button primary icon style={btnPrevStyle} onClick={() => this.open("page" + (index - 2))}>
									Prev: {content.pages[index - 1].title}
								</Button>
							</Modal.Actions>
						</Modal>
					);
				}
			});
		}

		if (fullContent.length > 0) {
			fullContent.map((page, index) => {
				if (index === fullContent.length - 1) {
					pages.push(page);
				} else {
					const curPage = "page" + index;
					pages.push(
						<Modal key={curPage} open={this.state[curPage]} onClose={() => this.close(content)}>
							{this.state.showPopup && (
								<OutsideClickHandler onOutsideClick={() => this.handleClickOutside()}>
									<div className="popup-help-container">{this.buildMenu(content)}</div>
								</OutsideClickHandler>
							)}
							<Modal.Header style={modalHeaderStyle}>
								<p className="help-modal-title">{content.pages[index + 1].title}</p>
								<HamburgerMenu togglePopup={() => this.togglePopup()} showPopup={this.state.showPopup} />
							</Modal.Header>
							<Modal.Content style={modalContentStyle}>
								<Modal.Description style={modalDescriptionStyle}>
									{this.contentLines(content.pages[index + 1].contentLines)}
								</Modal.Description>
							</Modal.Content>
							<Modal.Actions style={modalActionsStyle}>
								<Button primary icon style={btnProceedStyle} onClick={() => this.open("page" + (index + 1))}>
									Next: {content.pages[index + 2].title}
								</Button>
								<Button
									primary
									icon
									style={btnPrevStyle}
									onClick={() => {
										if (index !== 0) {
											this.open("page" + (index - 1));
										} else {
											this.close(content);
											this.props.openHelp();
										}
									}}>
									Prev: {content.pages[index].title}
								</Button>
							</Modal.Actions>
						</Modal>
					);
				}
			});
		}
		return pages;
	};
	render() {
		const content = this.state.content;
		const mainModalOpen = this.state.mainModalOpen;
		const nestedContent = this.state.nestedContent;

		if (content && content.pages) {
			return (
				<div className="help-modals">
					<Modal open={this.props.open} onClose={() => this.props.close()} style={modalStyle}>
						{this.state.showPopup && (
							<OutsideClickHandler onOutsideClick={this.handleClickOutside.bind(this)}>
								<div className="popup-help-container">{this.buildMenu(content)}</div>
							</OutsideClickHandler>
						)}

						<Modal.Header style={modalHeaderStyle}>
							<HamburgerMenu togglePopup={() => this.togglePopup()} showPopup={this.state.showPopup} />
							<p className="help-modal-title">{content.pages[0].title}</p>
						</Modal.Header>
						<Modal.Content style={modalContentStyle}>
							<Modal.Description style={modalDescriptionStyle}>
								{this.contentLines(content.pages[0].contentLines)}
							</Modal.Description>
						</Modal.Content>
						<Modal.Actions style={modalActionsStyle}>
							<Button primary icon style={btnProceedStyle} onClick={() => this.open("page0")}>
								Next: {content.pages[1].title}
							</Button>
						</Modal.Actions>
					</Modal>
					{this.buildContent()}
				</div>
			);
		} else {
			return "";
		}
	}
}

export default HelpModal;

const modalStyle = {
	position: "relative"
};

const modalDescriptionStyle = {
	color: "white",
	padding: "16px"
};

const modalContentStyle = {
	backgroundColor: "#27272C"
};

const modalHeaderStyle = {
	color: "white",
	backgroundColor: "#4B4A50",
	borderRadius: 0,
	textAlign: "center"
};

const modalActionsStyle = {
	backgroundColor: "#4B4A50",
	borderRadius: 0,
	padding: "16px"
};

const btnProceedStyle = {
	borderRadius: 0
};

const btnPrevStyle = {
	borderRadius: 0,
	float: "left"
};

const inlinestyle = {
	display: "inline-block"
};

const popupHelpStyle = {
	transition: "all ease-in .3s",
	height: "auto"
};
