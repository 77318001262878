import React from "react";
import ContentLoader from "react-content-loader";

const LoginLoader = (props) => (
	<ContentLoader speed={2} width={140} height={14} viewBox="0 0 140 14" backgroundColor="#1a1a1f" foregroundColor="#ecebeb" {...props} style={{opacity: ".1"}}>
		<rect x="0" y="0" rx="0" ry="0" width="140" height="14" />
	</ContentLoader>
);

export default LoginLoader;
