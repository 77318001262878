import React from "react";
const IconsWeHave = [
	"microsoft.apimanagement/default.svg",
	"microsoft.apimanagement/service.svg",
	"microsoft.apimanagement/service/apis.svg",
	"microsoft.apimanagement/service/products.svg",
	"microsoft.apimanagement/service/users.svg",
	"microsoft.apimanagement/service/apis/operations.svg",
	"microsoft.authorization/locks.svg",
	"microsoft.authorization/policyassignments.svg",
	"microsoft.authorization/policydefinitions.svg",
	"microsoft.authorization/policysetdefinitions.svg",
	"microsoft.authorization/roleassignments.svg",
	"microsoft.authorization/roledefinitions.svg",
	"microsoft.automation/automationaccounts.svg",
	"microsoft.automation/automationaccounts/certificates.svg",
	"microsoft.automation/automationaccounts/compilationjobs.svg",
	"microsoft.automation/automationaccounts/configurations.svg",
	"microsoft.automation/automationaccounts/connections.svg",
	"microsoft.automation/automationaccounts/connectiontypes.svg",
	"microsoft.automation/automationaccounts/credentials.svg",
	"microsoft.automation/automationaccounts/jobs.svg",
	"microsoft.automation/automationaccounts/jobschedules.svg",
	"microsoft.automation/automationaccounts/modules.svg",
	"microsoft.automation/automationaccounts/runbooks.svg",
	"microsoft.automation/automationaccounts/schedules.svg",
	"microsoft.automation/automationaccounts/variables.svg",
	"microsoft.batch/batchaccounts.svg",
	"microsoft.botservice/botservices.svg",
	"microsoft.botservice/botservices/channels.svg",
	"microsoft.botservice/botservices/connections.svg",
	"microsoft.cache/redis.svg",
	"microsoft.cdn/profiles.svg",
	"microsoft.cdn/profiles/endpoints.svg",
	"microsoft.cognitiveservices/accounts.svg",
	"microsoft.compute/availabilitysets.svg",
	"microsoft.compute/disks.svg",
	"microsoft.compute/virtualmachines-linux.svg",
	"microsoft.compute/virtualmachines.svg",
	"microsoft.compute/virtualmachinescalesets.svg",
	"microsoft.compute/virtualmachines/extensions.svg",
	"microsoft.compute/virtualmachines/microsoft.compute/virtualmachines/extensions.svg",
	"microsoft.containerinstance/containergroups.svg",
	"microsoft.containerregistry/registries.svg",
	"microsoft.containerservice/containerservices.svg",
	"microsoft.containerservice/managedclusters.svg",
	"microsoft.databricks/workspaces.svg",
	"microsoft.datafactory/factories.svg",
	"microsoft.datalakestore/accounts.svg",
	"microsoft.dbformysql/servers.svg",
	"microsoft.dbformysql/servers/configurations.svg",
	"microsoft.dbformysql/servers/databases.svg",
	"microsoft.dbformysql/servers/firewallrules.svg",
	"microsoft.dbforpostgresql/servers.svg",
	"microsoft.dbforpostgresql/servers/configurations.svg",
	"microsoft.dbforpostgresql/servers/databases.svg",
	"microsoft.dbforpostgresql/servers/firewallrules.svg",
	"microsoft.devices/iothubs.svg",
	"microsoft.devices/iothubs/eventhubendpoints/consumergroups.svg",
	"microsoft.devtestlab/labs.svg",
	"microsoft.devtestlab/schedules.svg",
	"microsoft.documentdb/databaseaccounts.svg",
	"microsoft.documentdb/databaseaccounts/apis/databases.svg",
	"microsoft.documentdb/databaseaccounts/apis/tables.svg",
	"microsoft.documentdb/databaseaccounts/apis/databases/collections.svg",
	"microsoft.documentdb/databaseaccounts/apis/databases/containers.svg",
	"microsoft.eventgrid/eventsubscriptions.svg",
	"microsoft.eventgrid/topics.svg",
	"microsoft.eventgrid/topics/providers/eventsubscriptions.svg",
	"microsoft.eventhub/namespaces.svg",
	"microsoft.eventhub/namespaces/eventhubs.svg",
	"microsoft.eventhub/namespaces/eventhubs/authorizationrules.svg",
	"microsoft.eventhub/namespaces/eventhubs/consumergroups.svg",
	"microsoft.hdinsight/clusters.svg",
	"microsoft.insights/alertrules.svg",
	"microsoft.insights/autoscalesettings.svg",
	"microsoft.insights/components.svg",
	"microsoft.insights/metricalerts.svg",
	"microsoft.insights/webtests.svg",
	"microsoft.keyvault/vaults.svg",
	"microsoft.keyvault/vaults/secrets.svg",
	"microsoft.kusto/clusters.svg",
	"microsoft.kusto/clusters/databases.svg",
	"microsoft.logic/integrationaccounts.svg",
	"microsoft.logic/workflows.svg",
	"microsoft.logic/integrationaccounts/agreements.svg",
	"microsoft.logic/integrationaccounts/partners.svg",
	"microsoft.machinelearningservices/workspaces.svg",
	"microsoft.migrate/projects.svg",
	"microsoft.network/applicationgateways.svg",
	"microsoft.network/azurefirewalls.svg",
	"microsoft.network/bastionhosts.svg",
	"microsoft.network/connections.svg",
	"microsoft.network/dnszones.svg",
	"microsoft.network/frontdoors.svg",
	"microsoft.network/frontdoorwebapplicationfirewallpolicies.svg",
	"microsoft.network/loadbalancers.svg",
	"microsoft.network/localnetworkgateways.svg",
	"microsoft.network/networkinterfaces.svg",
	"microsoft.network/networksecuritygroups.svg",
	"microsoft.network/privatednszones.svg",
	"microsoft.network/publicipaddresses.svg",
	"microsoft.network/routetables.svg",
	"microsoft.network/trafficmanagerprofiles.svg",
	"microsoft.network/virtualhubs.svg",
	"microsoft.network/virtualnetworkgateways.svg",
	"microsoft.network/virtualnetworks.svg",
	"microsoft.network/virtualwans.svg",
	"microsoft.network/vpngateways.svg",
	"microsoft.network/dnszones/a.svg",
	"microsoft.network/dnszones/c.svg",
	"microsoft.network/dnszones/txt.svg",
	"microsoft.network/loadbalancers/inboundnatrules.svg",
	"microsoft.network/networksecuritygroups/securityrules.svg",
	"microsoft.network/routetables/routes.svg",
	"microsoft.network/trafficmanagerprofiles/azureendpoints.svg",
	"microsoft.network/trafficmanagerprofiles/endpoints.svg",
	"microsoft.network/virtualnetworks/subnets.svg",
	"microsoft.network/virtualnetworks/virtualnetworkpeerings.svg",
	"microsoft.notificationhubs/namespaces.svg",
	"microsoft.notificationhubs/namespaces/notificationhubs.svg",
	"microsoft.operationalinsights/solutions.svg",
	"microsoft.operationalinsights/workspaces.svg",
	"microsoft.operationalinsights/workspaces/datasources.svg",
	"microsoft.operationalinsights/workspaces/savedsearches.svg",
	"microsoft.operationsmanagement/solutions.svg",
	"microsoft.resources/deployments.svg",
	"microsoft.resources/resourcegroups.svg",
	"microsoft.scheduler/jobcollections.svg",
	"microsoft.scheduler/jobcollections/jobs.svg",
	"microsoft.servicebus/namespaces.svg",
	"microsoft.servicebus/namespaces/authorizationrules.svg",
	"microsoft.servicebus/namespaces/queues.svg",
	"microsoft.servicebus/namespaces/topics.svg",
	"microsoft.servicebus/namespaces/queues/authorizationrules.svg",
	"microsoft.servicebus/namespaces/queues/subscriptions.svg",
	"microsoft.servicebus/namespaces/topics/authorizationrules.svg",
	"microsoft.servicebus/namespaces/topics/subscriptions.svg",
	"microsoft.servicebus/namespaces/topics/subscriptions/rules.svg",
	"microsoft.servicefabric/clusters.svg",
	"microsoft.sql/managedinstances.svg",
	"microsoft.sql/servers.svg",
	"microsoft.sql/managedinstances/databases.svg",
	"microsoft.sql/servers/advisors.svg",
	"microsoft.sql/servers/auditingpolicies.svg",
	"microsoft.sql/servers/auditingsettings.svg",
	"microsoft.sql/servers/databases.svg",
	"microsoft.sql/servers/firewallrules.svg",
	"microsoft.sql/servers/vulnerabilityassessments.svg",
	"microsoft.sql/servers/databases/advisors.svg",
	"microsoft.sql/servers/databases/auditingpolicies.svg",
	"microsoft.sql/servers/databases/auditingsettings.svg",
	"microsoft.sql/servers/databases/databases.svg",
	"microsoft.sql/servers/databases/firewallrules.svg",
	"microsoft.sql/servers/databases/transparentdataencryption.svg",
	"microsoft.sql/servers/databases/vulnerabilityassessments.svg",
	"microsoft.storage/storageaccounts.svg",
	"microsoft.storage/storageaccounts/blobservices.svg",
	"microsoft.storage/storageaccounts/blobservices/containers.svg",
	"microsoft.storage/storageaccounts/fileservices/shares.svg",
	"microsoft.web/apiapp.svg",
	"microsoft.web/certificates.svg",
	"microsoft.web/connections.svg",
	"microsoft.web/functionapp.svg",
	"microsoft.web/hostingenvironments.svg",
	"microsoft.web/mobileapp.svg",
	"microsoft.web/serverfarms.svg",
	"microsoft.web/serverfarmslinux.svg",
	"microsoft.web/sites.svg",
	"microsoft.web/sites/config.svg",
	"microsoft.web/sites/deployments.svg",
	"microsoft.web/sites/extensions.svg",
	"microsoft.web/sites/hostnamebindings.svg",
	"microsoft.web/sites/siteextensions.svg",
	"microsoft.web/sites/slots.svg",
	"microsoft.web/sites/sourcecontrols.svg",
	"microsoft.web/sites/slots/deployments.svg"
];

export const GenerateResourceIconsObject = () => {
    const resourceIcons = {};
    resourceIcons["resource"] = <img src={require(`../icons/ic_resource.svg`)} style={{ width: '100%', height: '100%' }} />

    IconsWeHave.forEach(icon => {
        const iconName = icon.replace(".svg", "");
        resourceIcons[iconName] = <img src={require(`../icons/${icon}`)} style={{ width: '100%', height: '100%' }} />
    })

    resourceIcons["resourceGroup"] = <img src={require(`../icons/ic_resource_group.svg`)} alt="resource-group" style={{ width: '100%', height: '100%' }} />;
	resourceIcons["tag"] = <img src={require(`../icons/ic_tag.svg`)} alt="tag" style={{ width: '100%', height: '100%' }} />;
    resourceIcons["subscription"] = <img src={require(`../icons/ic_subscription.svg`)} alt="subscription" style={{ width: '100%', height: '100%' }} />;
	resourceIcons["managementGroup"] = <img src={require(`../icons/ic_management_group.svg`)} alt="management-group" style={{ width: '100%', height: '100%' }} />;

	return resourceIcons;
}