import React, { Component } from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
import AppContext from "./utils/AppContext";
import config from "./utils/Config";
import * as msal from "@azure/msal-browser";
import { ToastContainer, toast } from "react-toastify";
import ErrorBoundary from "./ErrorBoundary";
import "./shared/global.css";

import "./shared/icons.css";
import "./components/elements/buttons/Button/Button.css";
import "./components/tag-manager/Components/ManagementGroupToggleMode/ManagementGroupToggleMode.css";
import "react-toastify/dist/ReactToastify.css";

var accountId;
const userAgentApplication = new msal.PublicClientApplication({
    auth: {
        clientId: config.appId,
        redirectUri: config.redirectUri,
        authority: config.authority,
        postLogoutRedirectUri: "http://localhost:3000",
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true,
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case msal.LogLevel.Error:
                        console.error(message);
                        return;
                    case msal.LogLevel.Info:
                        console.info(message);
                        return;
                    case msal.LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case msal.LogLevel.Warning:
                        console.warn(message);
                        return;
                }
            },
        },
    },
});
/**
 *  This is the Applications Context. Provides context as to whether someone is logged in
 *  or fetching a cost summary
 */

class ModernProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isAuthenticated: false,
            isLoading: true,
            iFrameSegmentFinished: false,
            userName: "",
            userEmail: "",
            accessToken: "",
            loginRetry: false,
            gettingCM: [],
            syncingCM: [],
            CmError: false,
            cmError400: false,
            cmError400Message: "",
            activeSubscription: {},
            currentlySelectedTags: [],
            elevatedSubscriptions: [],
            elevatedSubscriptionNames: [],
            expiryDate: "",
            accountType: "",
            defaultScope: "",
            defaultTag: [],
            defaultValues: [],
            defaultGroups: [],
            defaultValidationTags: [],
            otherValidationTags: [],
            otherScopes: null,
            accounts: [],
            defaultFilterTags: [],
            avdAvailable: false
        };
    }

    handleResponse = async (resp) => {
        const isInIframe = window.parent !== window;
        if (!isInIframe) {
            if (resp !== null) {
                accountId = resp.account.homeAccountId;
                this.getTokenPopup(
                    {
                        scopes: ["https://management.azure.com/user_impersonation"],
                        prompt: "select_account",
                    },
                    resp.account,
                );
            } else {
                try {
                    const currentAccounts = userAgentApplication.getAllAccounts();
                    accountId = currentAccounts[0].homeAccountId;
                    userAgentApplication
                        .acquireTokenSilent({
                            scopes: ["https://management.azure.com/user_impersonation"],
                            account: currentAccounts[0],
                        })
                        .then(async (response) => {
                            await this.checkLogin(response);
                        })
                        .catch(async (error) => {
                            this.setState({
                                isAuthenticated: false,
                                userName: "",
                                userEmail: "",
                                accessToken: "",
                                isLoading: false,
                                iFrameSegmentFinished: false,
                            });
                            if (error instanceof msal.InteractionRequiredAuthError) {
                                // await this.forceLogin();
                            }
                        });
                } catch (error) {
                    this.setState({
                        isAuthenticated: false,
                        userName: "",
                        userEmail: "",
                        accessToken: "",
                        isLoading: false,
                        iFrameSegmentFinished: false,
                    });
                    // await this.forceLogin();
                }
            }
        }
    };

    handleResponseAndAsk = async (resp) => {
        const isInIframe = window.parent !== window;
        if (!isInIframe) {
            if (resp !== null && resp !== undefined) {
                accountId = resp.account.homeAccountId;
                await this.getTokenPopup(
                    {
                        scopes: ["https://management.azure.com/user_impersonation"],
                        prompt: "select_account",
                    },
                    resp.account,
                );
            } else {
                try {
                    const currentAccounts = userAgentApplication.getAllAccounts();
                    accountId = currentAccounts[0].homeAccountId;
                    await userAgentApplication
                        .acquireTokenSilent({
                            scopes: ["https://management.azure.com/user_impersonation"],
                            account: currentAccounts[0],
                        })
                        .then(async (response) => {
                            await this.checkLogin(response);
                        })
                        .catch(async (error) => {
                            this.setState({
                                isAuthenticated: false,
                                userName: "",
                                userEmail: "",
                                accessToken: "",
                                isLoading: false,
                                iFrameSegmentFinished: true,
                            });
                            if (error instanceof msal.InteractionRequiredAuthError) {
                                await this.forceLogin();
                            }
                        });
                } catch (error) {
                    this.setState({
                        isAuthenticated: false,
                        userName: "",
                        userEmail: "",
                        accessToken: "",
                        isLoading: false,
                        iFrameSegmentFinished: true,
                    });
                    await this.forceLogin();
                }
            }
        }
    };

    handleResponseAndAskForLogAnalytics = async (resp) => {
        const isInIframe = window.parent !== window;
        let accessToken = "";
        if (!isInIframe) {
            const currentAccounts = userAgentApplication.getAllAccounts();

            await userAgentApplication
                .loginPopup({
                    scopes: ["https://westus2.api.loganalytics.io/Data.Read"],
                    prompt: "select_account",
                })
                .then(async (res) => {
                    accessToken = res.accessToken;
                });
        }
        return accessToken;
    };
    async getTokenPopup(request, account) {
        request.account = account;
        return await userAgentApplication.acquireTokenSilent(request).catch(async (error) => {
            if (error instanceof msal.InteractionRequiredAuthError) {
                return userAgentApplication.acquireTokenPopup(request).catch((error) => {});
            } else {
            }
        });
    }

    async forceLogin() {
        this.setState({ isLoading: true });

        await userAgentApplication
            .loginPopup({
                scopes: ["https://management.azure.com/user_impersonation"],
                prompt: "select_account",
            })
            .then(async (res) => {
                await this.checkLogin(res);
            })
            .catch((err) => {
                if (err instanceof msal.BrowserAuthError) {
                    window.location.href = "https://" + window.location.hostname + ":" + window.location.port + "/";
                }
                this.setState({
                    isAuthenticated: false,
                    userName: "",
                    userEmail: "",
                    accessToken: "",
                    isLoading: false,
                    iFrameSegmentFinished: true,
                });
            });
    }

    async FetchLogAnalyticsToken() {
        let accessToken = "";
        await userAgentApplication
            .handleRedirectPromise()
            .then(async (res) => {
                accessToken = await this.handleResponseAndAskForLogAnalytics(res);
            })
            .catch((err) => {});
        return accessToken;
    }

    async login() {
        this.setState({ isLoading: true });
        await userAgentApplication
            .handleRedirectPromise()
            .then(async (res) => {
                await this.handleResponseAndAsk(res);
            })
            .catch((err) => {});
    }

    login1 = async (res2, rej2) => {
        console.log("here")
        this.setState({ isLoading: true });
        return await new Promise(async (res1, rej1) => {
            userAgentApplication
                .handleRedirectPromise()
                .then(async (res) => {
                    await this.handleResponseAndAskPromise(res, res2, rej2, res1, rej1);
                })
                .catch((err) => {});
        });
    };

    // This function can be removed if you do not need to support IE

    /** GET USER PROFILE THEN UPDATE STATE
     *
     * @param {*} res
     * @param {*} rej
     */
    async getUserProfileThenRun(res, rej) {
        await this.login1(res, rej)
            .then(() => {
                res("success");
            })
            .catch(() => {
                rej("error");
            });
        this.setState({ loginRetry: true });
    }

    handleResponseAndAskPromise = async (resp, res2, rej2, res1, rej1) => {
        console.log("handling...");
        const isInIframe = window.parent !== window;
        if (!isInIframe) {
            if (resp !== null && resp !== undefined) {
                accountId = resp.account.homeAccountId;
                await this.getTokenPopup(
                    {
                        scopes: ["https://management.azure.com/user_impersonation"],
                        prompt: "select_account",
                    },
                    resp.account,
                )
                    .then((res) => {
                        res2("success");
                        res1("success");
                    })
                    .catch(() => {
                        rej1("error");
                        rej2("error");
                    });
            } else {
                try {
                    const currentAccounts = userAgentApplication.getAllAccounts();
                    accountId = currentAccounts[0].homeAccountId;
                    await userAgentApplication
                        .acquireTokenSilent({
                            scopes: ["https://management.azure.com/user_impersonation"],
                            account: currentAccounts[0],
                        })
                        .then(async (response) => {
                            await this.checkLogin(response)
                                .then(() => {
                                    res2("success");
                                    res1("success");
                                })
                                .catch((err) => {
                                    rej1("error");
                                    rej2("error");
                                });
                        })
                        .catch(async (error) => {
                            this.setState({
                                isAuthenticated: false,
                                userName: "",
                                userEmail: "",
                                accessToken: "",
                                isLoading: false,
                                iFrameSegmentFinished: true,
                            });
                            rej1("error");
                            rej2("error");

                            if (error instanceof msal.InteractionRequiredAuthError) {
                            }
                        });
                } catch (error) {
                    this.setState({
                        isAuthenticated: false,
                        userName: "",
                        userEmail: "",
                        accessToken: "",
                        isLoading: false,
                        iFrameSegmentFinished: true,
                    });
                    console.log("ERROR HERE");

                    rej1("error");
                    rej2("error");
                }
            }
        }
    };

    async logout() {
        console.log("ERROR HERE");

        await userAgentApplication.logoutPopup({
            postLogoutRedirectUri: "https://" + window.location.hostname + ":" + window.location.port + "/",
        });
        this.setState({
            isAuthenticated: false,
            userName: "",
            userEmail: "",
            accessToken: "",
            isLoading: false,
            iFrameSegmentFinished: false,
        });
    }

    resetLoginRetry1() {
        this.setState({ loginRetry: false });
    }

    setActiveSub(activeSubData) {
        this.setState({ activeSubscription: activeSubData });
    }

    checkLogin = async (response) => {
        let errorCode = 200;
        console.log(response);
        // This request triggers cost update
        // FromRetry is a variable that is true when a 403 has occured, and a request needs to be remade after a successful login has occured.
        // FromRetry is context based variable
        return await new Promise((res, rej) => {
            fetch("https://api-devtest.cloudclarity.app/api/tagmanager/checklogin", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    access_token: response.accessToken,
                }),
            })
                .then((response) => {
                    if (response.status !== 200) {
                        // Mcdodge are ia - theres an error but it might not be a bad error - go to error section
                        errorCode = response.status;
                        throw new Error();
                    } else {
                        return response.json();
                    }
                })
                .then((resa) => {
                    let res1 = JSON.parse(JSON.stringify(resa));
                    this.setState({
                        elevatedSubscriptions: res1["elevatedSubs"],
                        elevatedSubscriptionNames: res1["elevatedSubNames"],
                        expiryDate: res1["expiry"],
                        accountType: res1["type"],
                        defaultFilterTags: res1["defaultFilterTags"],
                        avdAvailable : res1["avdAvailable"]
                    });
                    if (res1["defaultPreferences"]["Scope"] !== this.state.defaultScope && res1["defaultPreferences"]["Scope"] !== null) {
                        this.setState({
                            defaultScope: JSON.parse(res1["defaultPreferences"]["Scope"]),
                        });
                    } else {
                        this.setState({
                            defaultScope: "",
                        });
                    }
                    if (res1["defaultPreferences"]["Tag"] !== this.state.defaultTag && res1["defaultPreferences"]["Tag"] !== null) {
                        this.setState({
                            defaultTag: JSON.parse(res1["defaultPreferences"]["Tag"]),
                        });
                    } else {
                        this.setState({
                            defaultTag: [],
                        });
                    }
                    if (res1["defaultPreferences"]["Values"] && res1["defaultPreferences"]["Values"] !== null) {
                        this.setState({
                            defaultValues: JSON.parse(res1["defaultPreferences"]["Values"]),
                        });
                    } else {
                        this.setState({
                            defaultValues: [],
                        });
                    }
                    if (res1["defaultPreferences"]["Groups"] && res1["defaultPreferences"]["Groups"] !== null) {
                        this.setState({
                            defaultGroups: JSON.parse(res1["defaultPreferences"]["Groups"]),
                        });
                    } else {
                        this.setState({
                            defaultGroups: [],
                        });
                    }
                    if (res1["defaultValidationTags"] !== this.state.defaultValidationTags) {
                        this.setState({
                            defaultValidationTags: JSON.parse(res1["defaultValidationTags"]) == null ? [] : JSON.parse(res1["defaultValidationTags"]),
                        });
                    } else {
                        this.setState({
                            defaultValidationTags: [],
                        });
                    }
                    if (res1["otherValidationTags"] !== this.state.otherValidationTags) {
                        this.setState({
                            otherValidationTags: JSON.parse(res1["otherValidationTags"]) == null ? [] : JSON.parse(res1["otherValidationTags"]),
                        });
                    } else {
                        this.setState({
                            otherValidationTags: [],
                        });
                    }
                    if (res1["otherPreferences"]["otherViews"]) {
                        let otherViews = JSON.parse(res1["otherPreferences"]["otherViews"]);
                        if (otherViews) {
                            this.setState({
                                otherScopes: otherViews,
                            });
                        }
                    } else {
                        this.setState({
                            otherScopes: null,
                        });
                    }

                    this.setState({
                        isAuthenticated: true,
                        accessToken: response.accessToken,
                        userName: response.idTokenClaims.name,
                        userEmail: response.idTokenClaims.preferred_username,
                        isLoading: false,
                        iFrameSegmentFinished: true,
                    });
                    return res(res1);
                })
                .catch((err) => {
                    this.setState({
                        elevatedSubscriptions: [],
                        elevatedSubscriptionNames: [],
                    });
                    rej([]);
                });
        });
    };

    /** DAILY TRIGGER AND INITIAL BUILD FUNCTION
     *
     * DESCRIPTION
     * Checks if the cost summary has been update since 10am previous day, if not, triggers a new build
     *
     * PARAMETERS
     * @param {*} subscriptionId - Azure Subscription you want to make update on
     * @param {*} context - I guess theres no real need we could just directly reference state but meh
     * @param {*} fromRetry - fromRetry tells us if this is a retry after an error state
     *
     *  API RESPONSES
     * 	200 - Already happened today
     *	206 - Update started
     *	409 - Update is already happening and trying to trigger again
     *	403 - Token needs to be renewed
     *  500/404 - Server Error
     */
    costSummaryTrigger(subscriptionId, context, type, fromRetry) {
        let errorCode = 200;
        if (this.state.gettingCM.includes(subscriptionId) !== true) {
            let deepCopy = JSON.parse(JSON.stringify(this.state.gettingCM));
            deepCopy.push(subscriptionId);
            this.setState({ gettingCM: deepCopy, CmError: false });
        }
        // This request triggers cost update
        // FromRetry is a variable that is true when a 403 has occured, and a request needs to be remade after a successful login has occured.
        // FromRetry is context based variable
        return new Promise((res, rej) => {
            fetch("https://api-devtest.cloudclarity.app/api/tagmanager/xtx/costsummarytrigger", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    access_token: this.state.accessToken,
                    type: type,
                    subscriptionId: subscriptionId,
                }),
            })
                .then((response) => {
                    if (response.status !== 200) {
                        // Mcdodge are ia - theres an error but it might not be a bad error - go to error section
                        errorCode = response.status;
                        throw new Error();
                    } else {
                        // Success!
                        if (this.state.gettingCM.includes(subscriptionId)) {
                            let deepCopy = JSON.parse(JSON.stringify(this.state.gettingCM));
                            let newGettingCM = deepCopy.filter((item) => item !== subscriptionId);
                            this.setState({
                                gettingCM: newGettingCM,
                                CmError: false,
                            });
                        }
                        return res(response.json());
                    }
                })
                .catch((err) => {
                    if (err.name === "AbortError") {
                        rej("AbortError");
                    }
                    if (fromRetry) {
                        // We only get here if this has errored out
                        // in which case we treat any error as a real error and not a token issue (this is to avoid endless loop)
                        rej("loginRetry");
                    } else {
                        if (errorCode == 206 || errorCode == 409) {
                            // 206 - Update started
                            // 409 - Update is already happening and trying to trigger again
                            // in this senario we just want to keep checking every 30 seconds
                            setTimeout(() => {
                                this.checkSummaryUpdate(subscriptionId, context, type, fromRetry);
                            }, 30000);
                            res("retrying");
                        } else if (errorCode == 403) {
                            // Access Token is bad, trying again after getting new access token
                            return new Promise((res1, rej) => {
                                context.renew().then((reg) => {
                                    res1(res("loginAttemptFinished"));
                                });
                            });
                        } else {
                            // Theres an actual error with the request, either 404, or 500. investigation is needed
                            let deepCopy = JSON.parse(JSON.stringify(this.state.gettingCM));
                            let newGettingCM = deepCopy.filter((item) => item !== subscriptionId);
                            this.setState({
                                gettingCM: newGettingCM,
                                CmError: true,
                            });
                            rej("error");
                        }
                    }
                });
        });
    }
    updateSelectedTags(selectedTags) {
        this.setState({
            currentlySelectedTags: selectedTags,
        });
    }
    /** FORCE UPDATE ON COST SUMMARY RECORDS
     *
     * DESCRIPTION
     * This is triggered when a user wants to force an update on a cost summary record
     *
     * @param {*} subscriptionId - Azure Subscription you want to make update on
     * @param {*} context - I guess theres no real need we could just directly reference state but meh
     * @param {*} fromRetry - fromRetry tells us if this is a retry after an error state
     *
     * API RESPONSES
     * 206 - Update stared
     * 409 - Already updating - doesnt trigger new check
     * 403 - Access token expired
     * 500 - Major server error
     *
     */
    updateCM(subscriptionId, context, type, fromRetry, selectedTags) {
        let errorCode = 200;
        this.setState({ currentlySelectedTags: selectedTags });
        if (this.state.syncingCM.includes(subscriptionId) !== true) {
            let deepCopy = JSON.parse(JSON.stringify(this.state.syncingCM));
            deepCopy.push(subscriptionId);
            this.setState({ syncingCM: deepCopy, CmError: false });
        }
        // This request triggers cost update
        // FromRetry is a variable that is true when a 403 has occured, and a request needs to be remade after a successful login has occured.
        // FromRetry is context based variable
        return new Promise((res, rej) => {
            fetch("https://api-devtest.cloudclarity.app/api/tagmanager/costsummaryupdate", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    access_token: this.state.accessToken,
                    subscriptionId: subscriptionId,
                }),
            })
                .then((response) => {
                    if (response.status !== 200) {
                        // Mcdodge are ia - theres an error but it might not be a bad error - go to error section
                        errorCode = response.status;
                        throw new Error();
                    } else {
                        // Success!
                        if (this.state.syncingCM.includes(subscriptionId)) {
                            let deepCopy = JSON.parse(JSON.stringify(this.state.syncingCM));
                            let newGettingCM = deepCopy.filter((item) => item !== subscriptionId);
                            this.setState({
                                syncingCM: newGettingCM,
                                CmError: false,
                            });
                        }
                        return res(response.json());
                    }
                })
                .catch((err) => {
                    if (err.name === "AbortError") {
                        rej("AbortError");
                    }

                    if (fromRetry) {
                        // We only get here if this has errored out
                        // in which case we treat any error as a real error and not a token issue (this is to avoid endless loop)
                        rej("loginRetry");
                    } else {
                        if (errorCode == 206 || errorCode == 409) {
                            // 206 - Update started
                            // 409 - Update is already happening and trying to trigger again
                            // in this senario we just want to keep checking every 30 seconds
                            setTimeout(() => {
                                this.checkSummaryUpdate(subscriptionId, context, type, fromRetry);
                            }, 10000);
                            res("retrying");
                        } else if (errorCode == 403) {
                            // Access Token is bad, trying again after getting new access token
                            return new Promise((res1, rej) => {
                                context.renew().then((reg) => {
                                    res1(res("loginAttemptFinished"));
                                });
                            });
                        } else {
                            //Theres an actual error with the request, either 404, or 500. investigation is needed
                            let deepCopy = JSON.parse(JSON.stringify(this.state.syncingCM));
                            let newGettingCM = deepCopy.filter((item) => item !== subscriptionId);
                            this.setState({
                                syncingCM: newGettingCM,
                                CmError: true,
                            });
                            rej("error");
                        }
                    }
                });
        });
    }

    /** CHECK IF COST SUMMARY RECORDS ARE UP TO DATE AND FINISHED PROCESSING
     *
     * DESCRIPTION
     * This is called after a cost summary update is triggered, and checks if update is finished
     *
     * @param {*} subscriptionId - Azure Subscription you want to make update on
     * @param {*} context - I guess theres no real need we could just directly reference state but meh
     * @param {*} fromRetry - fromRetry tells us if this is a retry after an error state
     *
     * API RESPONSES
     * 200 - Finished Updated
     * 206 - Continuing to update
     * 401 - Acess Token expired - The trigger was getting data, and the access_token expired, uploaded an access_toek
     * 403 - Access token expired - if this happens we need to trigger daily trigger again
     * 500 - Major server error
     *
     */

    checkSummaryUpdate(subscriptionId, context, type, fromRetry) {
        let errorCode = 200;
        let message = "";
        //This is called after a cost summary update is triggered, and checks if update is finished
        if (this.state.syncingCM.includes(subscriptionId) !== true) {
            let deepCopy = JSON.parse(JSON.stringify(this.state.syncingCM));
            deepCopy.push(subscriptionId);
            this.setState({ syncingCM: deepCopy, CmError: false });
        }
        return new Promise((res, rej) => {
            fetch("https://api-devtest.cloudclarity.app/api/tagmanager/checksummaryupdate", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    access_token: this.state.accessToken,
                    subscriptionId: subscriptionId,
                    type: type,
                }),
            })
                .then(async (response) => {
                    if (response.status !== 200) {
                        // Mcdodge are ia - theres an error but it might not be a bad error - go to error section
                        errorCode = response.status;
                        message = await response.json();
                        throw new Error();
                    } else {
                        // Success!
                        // Deep Copy Syncing Cost Summary
                        let deepCopySyncing = JSON.parse(JSON.stringify(this.state.syncingCM));
                        let newCMSyncing = deepCopySyncing.filter((item) => item !== subscriptionId);
                        // Deep Copy Getting Cost Summary
                        let deepCopyGetting = JSON.parse(JSON.stringify(this.state.gettingCM));
                        let newGettingCM = deepCopyGetting.filter((item) => item !== subscriptionId);
                        // Update Context with these values
                        this.setState({
                            syncingCM: newCMSyncing,
                            gettingCM: newGettingCM,
                            CmError: false,
                        });

                        return res(response.json());
                    }
                })
                .catch((err) => {
                    if (err.name === "AbortError") {
                        rej("AbortError");
                    }
                    //we caught an error on our backend
                    if (errorCode == 400) {
                        this.setState({
                            cmError400: true,
                            cmError400Message: message,
                        });
                    }
                    if (errorCode == 206 || errorCode == 409) {
                        // 206 - Update started
                        // 409 - Update is already happening and trying to trigger again
                        // in this senario we just want to keep checking every 30 seconds
                        setTimeout(
                            () => {
                                this.checkSummaryUpdate(subscriptionId, context, type, fromRetry);
                            },
                            type !== "historic" ? 3000 : 10000,
                        );
                        res("retrying");
                    } else if (errorCode == 401) {
                        // Access Token is bad, trying again after getting new access token
                        return new Promise((res1, rej) => {
                            context.renew().then((reg) => {
                                this.costSummaryTrigger(subscriptionId, context, type, false);
                                res1(res("accesToken"));
                            });
                        });
                    } else if (errorCode == 403) {
                        // Access Token is bad, trying again after getting new access token
                        if (fromRetry) {
                            // We only get here if this has errored out
                            // in which case we treat any error as a real error and not a token issue (this is to avoid endless loop)
                            rej("loginRetry");
                        } else {
                            return new Promise((res1, rej) => {
                                context.renew().then((reg) => {
                                    this.checkSummaryUpdate(subscriptionId, context, type, true);
                                    res1(res("loginAttemptFinished"));
                                });
                            });
                        }
                    } else {
                        // Theres an actual error with the request, either 404, or 500. investigation is needed
                        // Deep Copy Syncing Cost Summary
                        let deepCopySyncing = JSON.parse(JSON.stringify(this.state.syncingCM));
                        let newGettingCMSyncing = deepCopySyncing.filter((item) => item !== subscriptionId);
                        // Deep Copy Getting Cost Summary
                        let deepCopyGetting = JSON.parse(JSON.stringify(this.state.gettingCM));
                        let newGettingCM = deepCopyGetting.filter((item) => item !== subscriptionId);
                        // Update Context with these values
                        this.setState({
                            syncingCM: newGettingCMSyncing,
                            gettingCM: newGettingCM,
                            CmError: true,
                        });
                        rej("error");
                    }
                });
        });
    }

    getCostEditView = (tagKey, subscriptionId, fromRetry) => {
        let errorCode = 200;

        return new Promise((res, rej) => {
            fetch("https://api-devtest.cloudclarity.app/api/tagmanager/xtx/costEditView", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    access_token: this.state.accessToken,
                    subscriptionId: subscriptionId,
                    tagKey: tagKey,
                }),
            })
                .then((response) => {
                    if (response.status !== 200) {
                        // Mcdodge are ia - theres an error but it might not be a bad error - go to error section
                        errorCode = response.status;
                        throw new Error();
                    } else {
                        // Success!
                        return res(response.json());
                    }
                })
                .catch((err) => {
                    if (err.name === "AbortError") {
                        rej("AbortError");
                    }
                    if (fromRetry) {
                        // We only get here if this has errored out
                        // in which case we treat any error as a real error and not a token issue (this is to avoid endless loop)
                        rej("loginRetry");
                    } else {
                        if (errorCode == 403) {
                            // Access Token is bad, trying again after getting new access token
                            return new Promise((res1, rej) => {
                                this.getUserProfileThenRun(res1, rej).then((reg) => {
                                    res1(res(this.getCostEditView(tagKey, subscriptionId, true)));
                                });
                            });
                        } else {
                            rej("error");
                        }
                    }
                });
        });
    };

    sendCostUpdateTags = async (resources, versionHistoryEntry, allChanges, fromRetry) => {
        let errorCode = 200;
        return new Promise((res, rej) => {
            fetch("https://api-devtest.cloudclarity.app/api/tagmanager/xtx/setresourcetags", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    versionHistoryEntry: versionHistoryEntry,
                    allChanges: allChanges,
                    access_token: this.state.accessToken,
                    resources: resources,
                    operation: "Merge",
                }),
            })
                .then(async (res1) => {
                    if (res1.status == 200 || res1.status == 206 || res1.status == 204) {
                        errorCode = res1.status;
                        let x = await res1.json();
                        return res([x, errorCode]);
                    } else {
                        errorCode = res1.status;
                        throw new Error();
                    }
                })
                .catch((err) => {
                    if (err.name === "AbortError") {
                        rej("AbortError");
                    }
                    if (fromRetry) {
                        // We only get here if this has errored out
                        // in which case we treat any error as a real error and not a token issue (this is to avoid endless loop)
                        rej("loginRetry");
                    } else {
                        if (errorCode == 403) {
                            // Access Token is bad, trying again after getting new access token
                            return new Promise((res1, rej) => {
                                this.getUserProfileThenRun(res1, rej).then((reg) => {
                                    res1(res(this.sendCostUpdateTags(resources, versionHistoryEntry, allChanges, true)));
                                });
                            });
                        } else {
                            rej(err);
                        }
                    }
                });
        });
    };

    sendCostDeleteTags = (Ids, subscriptionId, tagKey, fromRetry) => {
        let errorCode = 200;
        return new Promise((res, rej) => {
            fetch("https://api-devtest.cloudclarity.app/api/tagmanager/xtx/deleteKey", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    access_token: this.state.accessToken,
                    tagKeys: [{ tagKey: tagKey, Ids: Ids }],
                    subscriptionId: subscriptionId,
                    type: "res",
                }),
            })
                .then(async (res1) => {
                    if (res1.status == 200 || res1.status == 206 || res1.status == 204) {
                        errorCode = res1.status;
                        let x = await res1.json();
                        return res([x, errorCode]);
                    } else {
                        errorCode = res1.status;
                        throw new Error();
                    }
                })
                .catch((err) => {
                    if (err.name === "AbortError") {
                        rej("AbortError");
                    }
                    if (fromRetry) {
                        // We only get here if this has errored out
                        // in which case we treat any error as a real error and not a token issue (this is to avoid endless loop)
                        rej("loginRetry");
                    } else {
                        if (errorCode == 403) {
                            // Access Token is bad, trying again after getting new access token
                            return new Promise((res1, rej) => {
                                this.getUserProfileThenRun(res1, rej).then((reg) => {
                                    res1(res(this.sendCostDeleteTags(Ids, subscriptionId, true)));
                                });
                            });
                        } else {
                            rej("error");
                        }
                    }
                });
        });
    };

    sendUpdateTagsDelete = async (tagKeys, type, subscriptionId, versionHistoryEntry, tagsToDelete, fromRetry) => {
        let errorCode = 200;
        return new Promise((res, rej) => {
            fetch("https://api-devtest.cloudclarity.app/api/tagmanager/xtx/deletekey", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    access_token: this.state.accessToken,
                    tagKeys: [tagKeys],
                    type: type,
                    subscriptionId: subscriptionId,
                    versionHistoryEntry,
                    allUpdates: tagsToDelete,
                }),
            })
                .then(async (res1) => {
                    errorCode = res1.status;
                    let x = await res1.json();
                    return res([x, errorCode]);
                })
                .catch((err) => {
                    if (err.name === "AbortError") {
                        rej("AbortError");
                    }
                    if (fromRetry) {
                        // We only get here if this has errored out
                        // in which case we treat any error as a real error and not a token issue (this is to avoid endless loop)
                        rej("loginRetry");
                    } else {
                        if (errorCode == 403) {
                            // Access Token is bad, trying again after getting new access token
                            return new Promise((res1, rej) => {
                                this.getUserProfileThenRun(res1, rej).then((reg) => {
                                    res1(res(this.sendUpdateTagsDelete(tagKeys, type, subscriptionId, versionHistoryEntry, tagsToDelete, true)));
                                });
                            });
                        } else {
                            rej("error");
                        }
                    }
                });
        });
    };

    sendUpdateTags = async (versionHistory, resources, operation, allChanges, fromRetry) => {
        let errorCode = 200;
        return new Promise((res, rej) => {
            fetch("https://api-devtest.cloudclarity.app/api/tagmanager/xtx/setresourcetags", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    versionHistoryEntry: versionHistory,
                    access_token: this.state.accessToken,
                    allChanges: allChanges,
                    resources: resources,
                    operation: operation,
                }),
            })
                .then(async (res1) => {
                    errorCode = res1.status;
                    let x = await res1.json();
                    return res([x, errorCode]);
                })
                .catch((err) => {
                    if (err.name === "AbortError") {
                        rej("AbortError");
                    }
                    if (fromRetry) {
                        // We only get here if this has errored out
                        // in which case we treat any error as a real error and not a token issue (this is to avoid endless loop)
                        rej("loginRetry");
                    } else {
                        if (errorCode == 403) {
                            // Access Token is bad, trying again after getting new access token
                            return new Promise((res1, rej) => {
                                this.getUserProfileThenRun(res1, rej).then((reg) => {
                                    res1(res(this.sendUpdateTags(versionHistory, resources, operation, allChanges, true)));
                                });
                            });
                        } else {
                            rej("error");
                        }
                    }
                });
        });
    };

    fetchManagementGroups = (signal, fromRetry) => {
        let errorCode = 200;
        return new Promise((res, rej) => {
            fetch("https://api-devtest.cloudclarity.app/api/tagmanager/getmanagementgroups", {
                signal,
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ access_token: this.state.accessToken }),
            })
                .then(async (res1) => {
                    errorCode = res1.status;
                    let x = await res1.json();
                    return res(x);
                })
                .catch((err) => {
                    if (err.name === "AbortError") {
                        rej("AbortError");
                    }
                    if (fromRetry) {
                        // We only get here if this has errored out
                        // in which case we treat any error as a real error and not a token issue (this is to avoid endless loop)
                        rej("loginRetry");
                    } else {
                        if (errorCode == 403) {
                            // Get New Token
                            return new Promise((res1, rej) => {
                                this.getUserProfileThenRun(res1, rej).then((reg) => {
                                    res1(res(this.fetchManagementGroups(true)));
                                });
                            });
                        } else {
                            rej("error");
                        }
                    }
                });
        });
    };

    fetchManagementGroupsSummary = (signal, fromRetry, hierarchy) => {
        let errorCode = 200;
        return new Promise((res, rej) => {
            fetch("https://api-devtest.cloudclarity.app/api/tagmanager/getmanagementgroupsresourcetagsummary", {
                signal,
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    access_token: this.state.accessToken,
                    hierarchy: hierarchy,
                }),
            })
                .then(async (res1) => {
                    errorCode = res1.status;
                    let x = await res1.json();
                    return res(x);
                })
                .catch((err) => {
                    if (err.name === "AbortError") {
                        rej("AbortError");
                    }
                    if (fromRetry) {
                        // We only get here if this has errored out
                        // in which case we treat any error as a real error and not a token issue (this is to avoid endless loop)
                        rej("loginRetry");
                    } else {
                        if (errorCode == 403) {
                            // Get New Token
                            return new Promise((res1, rej) => {
                                this.getUserProfileThenRun(res1, rej).then((reg) => {
                                    res1(res(this.fetchManagementGroupsSummary(true, hierarchy)));
                                });
                            });
                        } else {
                            rej("error");
                        }
                    }
                });
        });
    };

    getTagInfo = async (tag, subId, fromRetry, mode) => {
        let errorCode = 200;
        return new Promise((res, rej) => {
            fetch(
                mode == "resource"
                    ? "https://api-devtest.cloudclarity.app/api/tagmanager/xtx/getsubscriptiontaginfo"
                    : "https://api-devtest.cloudclarity.app/api/tagmanager/xtx/getsubscriptiontaginforg",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        access_token: this.state.accessToken,
                        headers: tag,
                        subscriptionId: subId,
                    }),
                },
            )
                .then((response) => {
                    if (response.status !== 200) {
                        // Mcdodge are ia - theres an error but it might not be a bad error - go to error section
                        errorCode = response.status;
                        throw new Error();
                    } else {
                        // Success!
                        return res(response.json());
                    }
                })

                .catch((err) => {
                    console.log(err);

                    if (fromRetry) {
                        // We only get here if this has errored out
                        // in which case we treat any error as a real error and not a token issue (this is to avoid endless loop)
                        rej("error");
                    } else {
                        if (errorCode == 403) {
                            // Access Token is bad, trying again after getting new access token
                            return new Promise((res1, rej) => {
                                this.getUserProfileThenRun(res1, rej).then((reg) => {
                                    res1(res(this.getTagInfo(tag, subId, true, mode)));
                                });
                            });
                        } else {
                            // Theres an actual error with the request, either 404, or 500. investigation is needed
                            rej("error");
                        }
                    }
                });
        });
    };

    setManagementGroupTags = (versionHistoryEntry, resources, allChanges, fromRetry) => {
        let errorCode = 200;
        return new Promise((res, rej) => {
            fetch("https://api-devtest.cloudclarity.app/api/tagmanager/xtx/setresourcetags", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    access_token: this.state.accessToken,
                    resources: resources,
                    operation: "Merge",
                    versionHistoryEntry: versionHistoryEntry,
                    allChanges: allChanges,
                }),
            })
                .then(async (res1) => {
                    errorCode = res1.status;
                    let x = await res1.json();
                    return res([x, errorCode]);
                })
                .catch((err) => {
                    if (fromRetry) {
                        // We only get here if this has errored out
                        // in which case we treat any error as a real error and not a token issue (this is to avoid endless loop)
                        rej("loginRetry");
                    } else {
                        if (errorCode == 403) {
                            // Get New Token
                            return new Promise((res1, rej) => {
                                this.getUserProfileThenRun(res1, rej).then((reg) => {
                                    res1(res(this.setManagementGroupTags(versionHistoryEntry, resources, allChanges, true)));
                                });
                            });
                        } else {
                            rej("error");
                        }
                    }
                });
        });
    };

    setManagementGroupRGTags = (resources, fromRetry) => {
        let errorCode = 200;
        return new Promise((res, rej) => {
            fetch("https://api-devtest.cloudclarity.app/api/tagmanager/xtx/setresourcegrouptags", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    access_token: this.state.accessToken,
                    resource_groups: resources,
                    operation: "Merge",
                }),
            })
                .then((response) => {
                    if (!response.ok) {
                        errorCode = response.status;
                        throw new Error();
                    } else {
                        errorCode = response.status;
                        return response.json();
                    }
                })
                .then((res1) => {
                    let x = res1;
                    x["statusCode"] = errorCode;
                    return res(x);
                })
                .catch((err) => {
                    if (fromRetry) {
                        // We only get here if this has errored out
                        // in which case we treat any error as a real error and not a token issue (this is to avoid endless loop)
                        rej("err");
                    } else {
                        if (errorCode == 403) {
                            // Get New Token
                            return new Promise((res1, rej) => {
                                this.getUserProfileThenRun(res1, rej).then((reg) => {
                                    res1(res(this.setManagementGroupRGTags(resources, true)));
                                });
                            });
                        } else {
                            rej("error");
                        }
                    }
                });
        });
    };

    getAllResourceTags = (signal, id, fromRetry) => {
        let errorCode = 200;
        return new Promise((res, rej) => {
            fetch("https://api-devtest.cloudclarity.app/api/tagmanager/getallresourcetags", {
                signal,
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    access_token: this.state.accessToken,
                    subscriptionId: id.replace("/subscriptions", ""),
                }),
            })
                .then((response) => {
                    if (!response.ok) {
                        errorCode = response.status;
                        throw new Error();
                    } else {
                        errorCode = response.status;
                        return response.json();
                    }
                })
                .then((res1) => {
                    let x = res1;
                    x["statusCode"] = errorCode;
                    return res(x);
                })
                .catch((err) => {
                    if (fromRetry) {
                        // We only get here if this has errored out
                        // in which case we treat any error as a real error and not a token issue (this is to avoid endless loop)
                        rej("err");
                    } else {
                        if (errorCode == 403) {
                            // Get New Token
                            return new Promise((res1, rej) => {
                                this.getUserProfileThenRun(res1, rej).then((reg) => {
                                    res1(res(this.getAllResourceTags(id, true)));
                                });
                            });
                        } else {
                            rej("error");
                        }
                    }
                });
        });
    };

    getScopedCosts = (signal, includedSubs, scopeType, scopeId, tagKeys, tagvalues, fromGraph, fromRetry) => {
        let errorCode = 200;
        return new Promise((res, rej) => {
            fetch("https://api-devtest.cloudclarity.app/api/dashboard/xtx/getscopecosts", {
                signal,
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    access_token: this.state.accessToken,
                    scopeType: scopeType,
                    scopeId: scopeId,
                    tagKeys: tagKeys,
                    tagValues: tagvalues,
                    includedSubs: includedSubs,
                    endpointType: "master",
                    fromGraph: fromGraph,
                }),
            })
                .then((response) => {
                    if (!response.ok) {
                        errorCode = response.status;
                        throw new Error();
                    } else {
                        errorCode = response.status;
                        return res(response.json());
                    }
                })

                .catch((err) => {
                    if (fromRetry) {
                        // We only get here if this has errored out
                        // in which case we treat any error as a real error and not a token issue (this is to avoid endless loop)
                        rej("err");
                    } else {
                        if (errorCode == 403) {
                            // Get New Token
                            return new Promise((res1, rej) => {
                                this.getUserProfileThenRun(res1, rej).then((reg) => {
                                    res1(res(this.getScopedCosts(signal, includedSubs, scopeType, scopeId, tagKeys, tagvalues, fromGraph, true)));
                                });
                            });
                        } else {
                            rej("error");
                        }
                    }
                });
        });
    };

    getDashboardCostSummary = (signal, includedSubs, scopeType, scopeId, tagKeys, tagvalues, tagKey, fromRetry) => {
        let errorCode = 200;
        return new Promise((res, rej) => {
            fetch("https://api-devtest.cloudclarity.app/api/dashboard/xtx/getcostsummary", {
                signal,
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    access_token: this.state.accessToken,
                    scopeType: scopeType,
                    scopeId: scopeId,
                    tagKeys: tagKeys,
                    tagValues: tagvalues,
                    includedSubs: includedSubs,
                    tagKey,
                }),
            })
                .then((response) => {
                    if (!response.ok) {
                        errorCode = response.status;
                        throw new Error();
                    } else {
                        errorCode = response.status;
                        return res(response.json());
                    }
                })

                .catch((err) => {
                    if (fromRetry) {
                        // We only get here if this has errored out
                        // in which case we treat any error as a real error and not a token issue (this is to avoid endless loop)
                        rej("err");
                    } else {
                        if (errorCode == 403) {
                            // Get New Token
                            return new Promise((res1, rej) => {
                                this.getUserProfileThenRun(res1, rej).then((reg) => {
                                    res1(res(this.getDashboardCostSummary(signal, includedSubs, scopeType, scopeId, tagKeys, tagvalues, tagKey, true)));
                                });
                            });
                        } else {
                            rej("error");
                        }
                    }
                });
        });
    };

    getChargebackResources = (includedSubs, scopeType, scopeId, tagKey, tagValue, fromRetry) => {
        let errorCode = 200;
        return new Promise((res, rej) => {
            fetch("https://api-devtest.cloudclarity.app/api/dashboard/xtx/chargebacks/getresources", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    access_token: this.state.accessToken,
                    scopeType: scopeType,
                    scopeId: scopeId,
                    includedSubs: includedSubs,
                    tagKey,
                    tagValue,
                }),
            })
                .then((response) => {
                    if (!response.ok) {
                        errorCode = response.status;
                        throw new Error();
                    } else {
                        errorCode = response.status;
                        return res(response.json());
                    }
                })

                .catch((err) => {
                    if (fromRetry) {
                        // We only get here if this has errored out
                        // in which case we treat any error as a real error and not a token issue (this is to avoid endless loop)
                        rej("err");
                    } else {
                        if (errorCode == 403) {
                            // Get New Token
                            return new Promise((res1, rej) => {
                                this.getUserProfileThenRun(res1, rej).then((reg) => {
                                    res1(res(this.getChargebackResources(includedSubs, scopeType, scopeId, tagKey, tagValue, true)));
                                });
                            });
                        } else {
                            rej("error");
                        }
                    }
                });
        });
    };

    getTiles = (signal, includedSubs, scopeType, scopeId, tagKeys, tagValues, fromRetry) => {
        let errorCode = 200;
        return new Promise((res, rej) => {
            fetch("https://api-devtest.cloudclarity.app/api/dashboard/xtx/gettiles", {
                signal,
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    access_token: this.state.accessToken,
                    scopeType: scopeType,
                    scopeId: scopeId,
                    tagKeys: tagKeys,
                    tagValues: tagValues,
                    includedSubs: includedSubs,
                }),
            })
                .then((response) => {
                    if (!response.ok) {
                        errorCode = response.status;
                        throw new Error();
                    } else {
                        errorCode = response.status;
                        return res(response.json());
                    }
                })

                .catch((err) => {
                    if (fromRetry) {
                        // We only get here if this has errored out
                        // in which case we treat any error as a real error and not a token issue (this is to avoid endless loop)
                        rej("err");
                    } else {
                        if (errorCode == 403) {
                            // Get New Token
                            return new Promise((res1, rej) => {
                                this.getUserProfileThenRun(res1, rej).then((reg) => {
                                    res1(res(this.getTiles(signal, includedSubs, scopeType, scopeId, tagKeys, tagValues, true)));
                                });
                            });
                        } else {
                            rej("error");
                        }
                    }
                });
        });
    };

    getSuggestions = (signal, includedSubs, scopeType, scopeId, tagKeys, tagValues, fromRetry) => {
        let errorCode = 200;
        return new Promise((res, rej) => {
            fetch("https://api-devtest.cloudclarity.app/api/dashboard/xtx/getsuggestions", {
                signal,
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    access_token: this.state.accessToken,
                    scopeType: scopeType,
                    scopeId: scopeId,
                    tagKeys: tagKeys,
                    tagValues: tagValues,
                    includedSubs: includedSubs,
                }),
            })
                .then((response) => {
                    if (!response.ok) {
                        errorCode = response.status;
                        throw new Error();
                    } else {
                        errorCode = response.status;
                        return res(response.json());
                    }
                })

                .catch((err) => {
                    if (fromRetry) {
                        // We only get here if this has errored out
                        // in which case we treat any error as a real error and not a token issue (this is to avoid endless loop)
                        rej("err");
                    } else {
                        if (errorCode == 403) {
                            // Get New Token
                            return new Promise((res1, rej) => {
                                this.getUserProfileThenRun(res1, rej).then((reg) => {
                                    res1(res(this.getSuggestions(signal, includedSubs, scopeType, scopeId, tagKeys, tagValues, true)));
                                });
                            });
                        } else {
                            rej("error");
                        }
                    }
                });
        });
    };

    updatePreferences = (defaultScope, defaultTag, defaultValue, defaultGroups, otherScopes, fromRetry) => {
        let errorCode = 200;

        return new Promise((res, rej) => {
            fetch("https://api-devtest.cloudclarity.app/api/tagmanager/updatePreferences", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    access_token: this.state.accessToken,
                    defaultScope: defaultScope,
                    defaultTag: defaultTag,
                    defaultValue: defaultValue,
                    defaultGroups: defaultGroups,
                    otherViews: otherScopes,
                }),
            })
                .then((response) => {
                    if (!response.ok) {
                        errorCode = response.status;
                        throw new Error();
                    } else {
                        errorCode = response.status;
                        let idTokenClaims = {};
                        idTokenClaims.name = this.state.userName;
                        idTokenClaims.preferred_username = this.state.userEmail;
                        this.checkLogin({
                            accessToken: this.state.accessToken,
                            idTokenClaims: idTokenClaims,
                        });
                        return res(response.json());
                    }
                })

                .catch((err) => {
                    if (fromRetry) {
                        // We only get here if this has errored out
                        // in which case we treat any error as a real error and not a token issue (this is to avoid endless loop)
                        rej("err");
                    } else {
                        if (errorCode == 403) {
                            // Get New Token
                            return new Promise((res1, rej) => {
                                this.getUserProfileThenRun(res1, rej).then((reg) => {
                                    res1(res(this.updatePreferences(defaultScope, defaultTag, defaultValue, defaultGroups, otherScopes, true)));
                                });
                            });
                        } else {
                            rej("error");
                        }
                    }
                });
        });
    };
    fetchResource = (subscriptionId, signal, fromRetry) => {
        let errorCode = 200;
        return new Promise((res, rej) => {
            fetch("https://api-devtest.cloudclarity.app/api/tagmanager/getallresourcetags", {
                signal,
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    access_token: this.state.accessToken,
                    subscriptionId: subscriptionId,
                }),
            })
                .then((response) => {
                    if (!response.ok) {
                        errorCode = response.status;
                        throw new Error();
                    } else {
                        return res(response.json());
                    }
                })
                .catch((err) => {
                    if (err.name === "AbortError") {
                        rej("AbortError");
                    }
                    if (fromRetry) {
                        // We only get here if this has errored out
                        // in which case we treat any error as a real error and not a token issue (this is to avoid endless loop)
                        rej("loginRetry");
                    } else {
                        if (errorCode == 404) {
                            rej("doesntExist");
                        }
                        if (errorCode == 403) {
                            // Get New Token
                            return new Promise((res1, rej) => {
                                this.getUserProfileThenRun(res1, rej).then((reg) => {
                                    res1(res(this.fetchResource(subscriptionId, signal, true)));
                                });
                            });
                        } else {
                            rej(err);
                        }
                    }
                });
        });
    };

    fetchResourceGroups = (signal, fromRetry, id) => {
        let errorCode = 200;
        return new Promise((res, rej) => {
            fetch("https://api-devtest.cloudclarity.app/api/tagmanager/getallresourcetags", {
                signal,
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    access_token: this.state.accessToken,
                    subscriptionId: id.replace("/subscriptions", ""),
                }),
            })
                .then((response) => {
                    if (!response.ok) {
                        errorCode = response.status;
                        throw new Error();
                    } else {
                        return res(response.json());
                    }
                })
                .catch((err) => {
                    if (err.name === "AbortError") {
                        rej("AbortError");
                    }
                    if (fromRetry) {
                        // We only get here if this has errored out
                        // in which case we treat any error as a real error and not a token issue (this is to avoid endless loop)
                        rej("loginRetry");
                    } else {
                        if (errorCode == 404) {
                            rej("doesntExist");
                        }
                        if (errorCode == 403) {
                            // Get New Token
                            return new Promise((res1, rej) => {
                                this.getUserProfileThenRun(res1, rej).then((reg) => {
                                    res1(res(this.fetchResourceGroups(signal, fromRetry, id)));
                                });
                            });
                        } else {
                            rej(err);
                        }
                    }
                });
        });
    };

    fetchResourceGroupsLegacy = (signal, fromRetry, id) => {
        let errorCode = 200;
        // This request triggers cost update
        // FromRetry is a variable that is true when a 403 has occured, and a request needs to be remade after a successful login has occured.
        // FromRetry is context based variable
        return new Promise((res, rej) => {
            fetch("https://api-devtest.cloudclarity.app/api/tagmanager/getallresourcegrouptags", {
                signal,
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    access_token: this.state.accessToken,
                    subscriptionId: id,
                }),
            })
                .then((response) => {
                    if (!response.ok) {
                        errorCode = response.status;
                        throw new Error();
                    } else {
                        return res(response.json());
                    }
                })
                .catch((err) => {
                    if (err.name === "AbortError") {
                        rej("AbortError");
                    }
                    if (fromRetry) {
                        // We only get here if this has errored out
                        // in which case we treat any error as a real error and not a token issue (this is to avoid endless loop)
                        rej("loginRetry");
                    } else {
                        if (errorCode == 404) {
                            rej("doesntExist");
                        }
                        if (errorCode == 403) {
                            // Get New Token
                            return new Promise((res1, rej) => {
                                this.getUserProfileThenRun(res1, rej).then((reg) => {
                                    res1(res(this.fetchResourceGroupsLegacy(signal, true, id)));
                                });
                            });
                        } else {
                            rej(err);
                        }
                    }
                });
        });
    };

    getExportResourcesDashboard = (signal, includedSubs, scopeType, scopeId, filteredTags, notTagged, groupBy, fromRetry) => {
        let errorCode = 200;
        let finalSig;
        if (signal == false) {
            return new Promise((res, rej) => {
                fetch("https://api-devtest.cloudclarity.app/api/dashboard/costsummary/resources/export", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + this.state.accessToken
                    },
                    body: JSON.stringify({
                        scopeType: scopeType,
                        scopeId: scopeId,
                        FilterTags: filteredTags,
                        notTagged: notTagged == null ? false : notTagged.value == "not-tagged" ? true : false,
                        groupBy: groupBy === "rg" ? 0 : 1,
                        includedSubs: includedSubs,
                    }),
                })
                    .then((response) => {
                        if (!response.ok) {
                            errorCode = response.status;
                            throw new Error();
                        } else {
                            return res(response.json());
                        }
                    })
                    .catch((err) => {
                        if (err.name === "AbortError") {
                            rej("AbortError");
                        }
                        if (fromRetry) {
                            // We only get here if this has errored out
                            // in which case we treat any error as a real error and not a token issue (this is to avoid endless loop)
                            rej("loginRetry");
                        } else {
                            if (errorCode == 404) {
                                rej("doesntExist");
                            }
                            if (errorCode == 403) {
                                // Get New Token
                                return new Promise((res1, rej) => {
                                    this.getUserProfileThenRun(res1, rej).then((reg) => {
                                        res1(res(this.getExportResourcesDashboard(signal, includedSubs, scopeType, scopeId,filteredTags, notTagged, groupBy, true)));
                                    });
                                });
                            } else {
                                rej(err);
                            }
                        }
                    });
            });
        } else {
            return new Promise((res, rej) => {
                fetch("https://api-devtest.cloudclarity.app/api/dashboard/costsummary/resources/export", {
                    signal,
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + this.state.accessToken
                    },
                    body: JSON.stringify({
                        scopeType: scopeType,
                        scopeId: scopeId,
                        FilterTags: filteredTags,
                        notTagged: notTagged == null ? false : notTagged.value == "not-tagged" ? true : false,
                        groupBy: groupBy === "rg" ? 0 : 1,
                        includedSubs: includedSubs === null ? [] : includedSubs,
                    }),
                })
                    .then((response) => {
                        if (!response.ok) {
                            errorCode = response.status;
                            throw new Error();
                        } else {
                            return res(response.json());
                        }
                    })
                    .catch((err) => {
                        if (err.name === "AbortError") {
                            rej("AbortError");
                        }
                        if (fromRetry) {
                            // We only get here if this has errored out
                            // in which case we treat any error as a real error and not a token issue (this is to avoid endless loop)
                            rej("loginRetry");
                        } else {
                            if (errorCode == 404) {
                                rej("doesntExist");
                            }
                            if (errorCode == 403) {
                                // Get New Token
                                return new Promise((res1, rej) => {
                                    this.getUserProfileThenRun(res1, rej).then((reg) => {
                                        res1(res(this.getExportResourcesDashboard(signal, includedSubs, scopeType, scopeId, filteredTags, notTagged, groupBy, true)));
                                    });
                                });
                            } else {
                                rej(err);
                            }
                        }
                    });
            });
        }
    };

    getScopedResources = (signal, includedSubs, scopeType, scopeId, filteredTags, notTagged, groupBy, fromRetry) => {
        let errorCode = 200;
        let finalSig;
        if (signal == false) {
            return new Promise((res, rej) => {
                fetch("https://api-devtest.cloudclarity.app/api/dashboard/costsummary/resources/get", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + this.state.accessToken
                    },
                    body: JSON.stringify({
                        scopeType: scopeType,
                        scopeId: scopeId,
                        filterTags: filteredTags,
                        notTagged: notTagged == null ? false : notTagged.value == "not-tagged" ? true : false,
                        groupBy: groupBy === "rg" ? 0 : 1,
                        includedSubs: includedSubs === null ? [] : includedSubs,
                    }),
                })
                    .then((response) => {
                        if (!response.ok) {
                            errorCode = response.status;
                            throw new Error();
                        } else {
                            return res(response.json());
                        }
                    })
                    .catch((err) => {
                        if (err.name === "AbortError") {
                            rej("AbortError");
                        }
                        if (fromRetry) {
                            // We only get here if this has errored out
                            // in which case we treat any error as a real error and not a token issue (this is to avoid endless loop)
                            rej("loginRetry");
                        } else {
                            if (errorCode == 404) {
                                rej("doesntExist");
                            }
                            if (errorCode == 403) {
                                // Get New Token
                                return new Promise((res1, rej) => {
                                    this.getUserProfileThenRun(res1, rej).then((reg) => {
                                        res1(res(this.getScopedResources(signal, includedSubs, scopeType, scopeId, filteredTags, notTagged, groupBy, true)));
                                    });
                                });
                            } else {
                                rej(err);
                            }
                        }
                    });
            });
        } else {
            return new Promise((res, rej) => {
                fetch("https://api-devtest.cloudclarity.app/api/dashboard/costsummary/resources/get", {
                    signal,
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + this.state.accessToken
                    }, 
                    body: JSON.stringify({
                        access_token: this.state.accessToken,
                        scopeType: scopeType,
                        scopeId: scopeId,
                        filterTags: filteredTags,
                        notTagged: notTagged == null ? false : notTagged.value == "not-tagged" ? true : false,
                        groupBy: groupBy === "rg" ? 0 : 1,
                        includedSubs: includedSubs === null ? [] : includedSubs,
                    }),
                })
                    .then((response) => {
                        if (!response.ok) {
                            errorCode = response.status;
                            throw new Error();
                        } else {
                            return res(response.json());
                        }
                    })
                    .catch((err) => {
                        if (err.name === "AbortError") {
                            rej("AbortError");
                        }
                        if (fromRetry) {
                            // We only get here if this has errored out
                            // in which case we treat any error as a real error and not a token issue (this is to avoid endless loop)
                            rej("loginRetry");
                        } else {
                            if (errorCode == 404) {
                                rej("doesntExist");
                            }
                            if (errorCode == 403) {
                                // Get New Token
                                return new Promise((res1, rej) => {
                                    this.getUserProfileThenRun(res1, rej).then((reg) => {
                                        res1(res(this.getScopedResources(signal, includedSubs, scopeType, scopeId, filteredTags, notTagged, groupBy, true)));
                                    });
                                });
                            } else {
                                rej(err);
                            }
                        }
                    });
            });
        }
    };

    getDashboardCostEditView = (signal, includedSubs, tagKey, subscriptionId, scopeType, scopeId, tagKeys, tagValues, notTagged, fromRetry) => {
        let errorCode = 200;
        return new Promise((res, rej) => {
            fetch("https://api-devtest.cloudclarity.app/api/dashboard/xtx/dashboardCostEditView", {
                signal,
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    access_token: this.state.accessToken,
                    subscriptionId: subscriptionId,
                    tagKey: tagKey,
                    scopeType: scopeType,
                    scopeId: scopeId,
                    tagKeys: tagKeys,
                    notTagged: notTagged == null ? false : notTagged.value == "not-tagged" ? true : false,
                    tagValues: tagValues,
                    includedSubs: includedSubs,
                }),
            })
                .then((response) => {
                    if (response.status !== 200) {
                        // Mcdodge are ia - theres an error but it might not be a bad error - go to error section
                        errorCode = response.status;
                        throw new Error();
                    } else {
                        // Success!
                        return res(response.json());
                    }
                })
                .catch((err) => {
                    if (err.name === "AbortError") {
                        rej("AbortError");
                    }
                    if (fromRetry) {
                        // We only get here if this has errored out
                        // in which case we treat any error as a real error and not a token issue (this is to avoid endless loop)
                        rej("loginRetry");
                    } else {
                        if (errorCode == 403) {
                            // Access Token is bad, trying again after getting new access token
                            return new Promise((res1, rej) => {
                                this.getUserProfileThenRun(res1, rej).then((reg) => {
                                    res1(res(this.getCostEditView(tagKey, subscriptionId, true)));
                                });
                            });
                        } else {
                            rej("error");
                        }
                    }
                });
        });
    };
    getMGExportData = (signal, allScopes, selectedScope, includedSubs, tagKeys, tagValues, tagsToValidate, fromRetry) => {
        let errorCode = 200;
        return new Promise((res, rej) => {
            fetch("https://api-devtest.cloudclarity.app/api/dashboard/xtx/getmgexportdata", {
                signal,
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    access_token: this.state.accessToken,
                    allScopes: allScopes,
                    selectedScope: selectedScope,
                    includedSubs: includedSubs,
                    tagKeys: tagKeys,
                    tagValues: tagValues,
                    tagsToValidate: tagsToValidate,
                }),
            })
                .then((response) => {
                    if (response.status !== 200) {
                        // Mcdodge are ia - theres an error but it might not be a bad error - go to error section
                        errorCode = response.status;
                        throw new Error();
                    } else {
                        // Success!
                        return res(response.json());
                    }
                })
                .catch((err) => {
                    if (err.name === "AbortError") {
                        rej("AbortError");
                    }
                    if (fromRetry) {
                        // We only get here if this has errored out
                        // in which case we treat any error as a real error and not a token issue (this is to avoid endless loop)
                        rej("loginRetry");
                    } else {
                        if (errorCode == 403) {
                            // Access Token is bad, trying again after getting new access token
                            return new Promise((res1, rej) => {
                                this.getUserProfileThenRun(res1, rej).then((reg) => {
                                    res1(res(this.getMGExportData(signal, allScopes, selectedScope, includedSubs, tagKeys, tagValues, fromRetry)));
                                });
                            });
                        } else {
                            rej("error");
                        }
                    }
                });
        });
    };

    getTagHealthReport = (signal, includedSubs, scopeType, scopeId, tagKeys, tagValues, keysToValidate, fromRetry) => {
        let finalKeys = [];

        keysToValidate.forEach((key) => {
            if (key[0] == " ") {
                finalKeys.push(key.substring(1, key.length));
            } else {
                finalKeys.push(key);
            }
        });
        let errorCode = 200;
        return new Promise((res, rej) => {
            fetch("https://api-devtest.cloudclarity.app/api/dashboard/xtx/gettaghealth", {
                signal,
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    access_token: this.state.accessToken,
                    scopeType: scopeType,
                    scopeId: scopeId,
                    tagKeys: tagKeys,
                    "tags-to-validate": finalKeys,
                    tagValues: tagValues,
                    includedSubs: includedSubs,
                }),
            })
                .then((response) => {
                    if (response.status !== 200) {
                        // Mcdodge are ia - theres an error but it might not be a bad error - go to error section
                        errorCode = response.status;
                        throw new Error();
                    } else {
                        // Success!
                        return res(response.json());
                    }
                })
                .catch((err) => {
                    if (err.name === "AbortError") {
                        rej("AbortError");
                    }
                    if (fromRetry) {
                        // We only get here if this has errored out
                        // in which case we treat any error as a real error and not a token issue (this is to avoid endless loop)
                        rej("loginRetry");
                    } else {
                        if (errorCode == 403) {
                            // Access Token is bad, trying again after getting new access token
                            return new Promise((res1, rej) => {
                                this.getUserProfileThenRun(res1, rej).then((reg) => {
                                    res1(res(this.getTagHealthReport(signal, includedSubs, scopeType, scopeId, tagKeys, tagValues, keysToValidate, fromRetry)));
                                });
                            });
                        } else {
                            rej("error");
                        }
                    }
                });
        });
    };

    savePreferences = (signal, defaultValidationTags, otherValidationTags, fromRetry) => {
        let errorCode = 200;
        return new Promise((res, rej) => {
            fetch("https://api-devtest.cloudclarity.app/api/dashboard/xtx/savevalidationtags", {
                signal,
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    access_token: this.state.accessToken,
                    defaultValidationTags: defaultValidationTags,
                    otherValidationTags: otherValidationTags,
                }),
            })
                .then((response) => {
                    if (response.status !== 200) {
                        // Mcdodge are ia - theres an error but it might not be a bad error - go to error section
                        errorCode = response.status;
                        throw new Error();
                    } else {
                        // Success!
                        return res(response.json());
                    }
                })
                .catch((err) => {
                    if (err.name === "AbortError") {
                        rej("AbortError");
                    }
                    if (fromRetry) {
                        // We only get here if this has errored out
                        // in which case we treat any error as a real error and not a token issue (this is to avoid endless loop)
                        rej("loginRetry");
                    } else {
                        if (errorCode == 403) {
                            // Access Token is bad, trying again after getting new access token
                            return new Promise((res1, rej) => {
                                this.getUserProfileThenRun(res1, rej).then((reg) => {
                                    res1(res(this.savePreferences(signal, defaultValidationTags, otherValidationTags, fromRetry)));
                                });
                            });
                        } else {
                            rej("error");
                        }
                    }
                });
        });
    };

    getCostTable = (subId, viewType, signal, fromRetry, groupBy) => {
        let errorCode = 200;
        let payload;
        // This request triggers cost update
        // FromRetry is a variable that is true when a 403 has occured, and a request needs to be remade after a successful login has occured.
        // FromRetry is context based variable
        groupBy !== undefined
            ? (payload = JSON.stringify({
                  access_token: this.state.accessToken,
                  subscriptionId: subId,
                  type: viewType,
                  view: groupBy,
              }))
            : (payload = JSON.stringify({
                  access_token: this.state.accessToken,
                  subscriptionId: subId,
                  type: viewType,
              }));
        return new Promise((res, rej) => {
            fetch("https://api-devtest.cloudclarity.app/api/tagmanager/xtx/getcostsummary", {
                signal,
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: payload,
            })
                .then((response) => {
                    if (response.status !== 200) {
                        // Mcdodge are ia - theres an error but it might not be a bad error - go to error section
                        errorCode = response.status;
                        throw new Error();
                    } else {
                        // Success!
                        return res(response.json());
                    }
                })
                .catch((err) => {
                    if (err.name === "AbortError") {
                        rej("AbortError");
                    }
                    if (fromRetry) {
                        // We only get here if this has errored out
                        // in which case we treat any error as a real error and not a token issue (this is to avoid endless loop)
                        rej("loginRetry");
                    } else {
                        if (errorCode == 404) {
                            rej("doesntExist");
                        }
                        if (errorCode == 403) {
                            // Access Token is bad, trying again after getting new access token
                            return new Promise((res1, rej) => {
                                this.getUserProfileThenRun(res1, rej).then((reg) => {
                                    res1(res(this.getCostTable(subId, viewType, signal, true, groupBy)));
                                });
                            });
                        } else {
                            rej("error");
                        }
                    }
                });
        });
    };

    getDBResourcesCost = (subId, signal, fromRetry) => {
        let errorCode = 200;
        let payload;
        // This request triggers cost update
        // FromRetry is a variable that is true when a 403 has occured, and a request needs to be remade after a successful login has occured.
        // FromRetry is context based variable

        payload = JSON.stringify({
            access_token: this.state.accessToken,
            subscriptionId: subId,
        });
        return new Promise((res, rej) => {
            fetch("https://api-devtest.cloudclarity.app/api/dashboard/getcostresources", {
                signal,
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: payload,
            })
                .then((response) => {
                    if (response.status !== 200) {
                        // Mcdodge are ia - theres an error but it might not be a bad error - go to error section
                        errorCode = response.status;
                        throw new Error();
                    } else {
                        // Success!
                        return res(response.json());
                    }
                })
                .catch((err) => {
                    if (err.name === "AbortError") {
                        rej("AbortError");
                    }
                    if (fromRetry) {
                        // We only get here if this has errored out
                        // in which case we treat any error as a real error and not a token issue (this is to avoid endless loop)
                        rej("loginRetry");
                    } else {
                        if (errorCode == 404) {
                            rej("doesntExist");
                        }
                        if (errorCode == 403) {
                            // Access Token is bad, trying again after getting new access token
                            return new Promise((res1, rej) => {
                                this.getUserProfileThenRun(res1, rej).then((reg) => {
                                    res1(res(this.getDBResourcesCost(subId, signal, true)));
                                });
                            });
                        } else {
                            rej("error");
                        }
                    }
                });
        });
    };
    getCostTableResources = (subId, viewType, fromRetry, groupBy, scope) => {
        let errorCode = 200;
        let payload;
        // This request triggers cost update
        // FromRetry is a variable that is true when a 403 has occured, and a request needs to be remade after a successful login has occured.
        // FromRetry is context based variable
        payload = JSON.stringify({
            access_token: this.state.accessToken,
            subscriptionId: subId,
            type: viewType,
            view: groupBy,
            scope: scope,
        });

        return new Promise((res, rej) => {
            fetch("https://api-devtest.cloudclarity.app/api/tagmanager/xtx/getcostsummary/resources", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: payload,
            })
                .then((response) => {
                    if (response.status !== 200) {
                        // Mcdodge are ia - theres an error but it might not be a bad error - go to error section
                        errorCode = response.status;
                        throw new Error();
                    } else {
                        // Success!
                        return res(response.json());
                    }
                })
                .catch((err) => {
                    if (err.name === "AbortError") {
                        rej("AbortError");
                    }
                    if (fromRetry) {
                        // We only get here if this has errored out
                        // in which case we treat any error as a real error and not a token issue (this is to avoid endless loop)
                        rej("loginRetry");
                    } else {
                        if (errorCode == 404) {
                            rej("doesntExist");
                        }
                        if (errorCode == 403) {
                            // Access Token is bad, trying again after getting new access token
                            return new Promise((res1, rej) => {
                                this.getUserProfileThenRun(res1, rej).then((reg) => {
                                    res1(res(this.getCostTableResources(subId, viewType, true, groupBy, scope)));
                                });
                            });
                        } else {
                            rej("error");
                        }
                    }
                });
        });
    };

    getCostTableResourcesDashboard = (includedSubs, scopeType, scopeId, filterTags, notTagged, groupBy, scope, fromRetry) => {
        let errorCode = 200;

        return new Promise((res, rej) => {
            fetch("https://api-devtest.cloudclarity.app/api/dashboard/costsummary/resources/get/resources", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization":"Bearer " +this.state.accessToken
                },
                body: JSON.stringify({
                    scopeType: scopeType,
                    scopeId: scopeId,
                    filterTags,
                    notTagged: notTagged == null ? false : notTagged.value == "not-tagged" ? true : false,
                    groupBy: groupBy === "rg" ? 0 : 1,
                    includedSubs: includedSubs === null ? [] : includedSubs,
                    view: groupBy,
                    scope: scope,
                }),
            })
                .then((response) => {
                    if (!response.ok) {
                        errorCode = response.status;
                        throw new Error();
                    } else {
                        return res(response.json());
                    }
                })
                .catch((err) => {
                    if (err.name === "AbortError") {
                        rej("AbortError");
                    }
                    if (fromRetry) {
                        // We only get here if this has errored out
                        // in which case we treat any error as a real error and not a token issue (this is to avoid endless loop)
                        rej("loginRetry");
                    } else {
                        if (errorCode == 404) {
                            rej("doesntExist");
                        }
                        if (errorCode == 403) {
                            // Get New Token
                            return new Promise((res1, rej) => {
                                this.getUserProfileThenRun(res1, rej).then((reg) => {
                                    res1(res(this.getCostTableResourcesDashboard(includedSubs, scopeType, scopeId, filterTags, notTagged, groupBy, scope, true)));
                                });
                            });
                        } else {
                            rej(err);
                        }
                    }
                });
        });
    };

    fetchManagementGroupsSummaryResourceGroups = (signal, fromRetry) => {
        let errorCode = 200;
        return new Promise((res, rej) => {
            fetch("https://api-devtest.cloudclarity.app/api/tagmanager/getmanagementgroupsresourcegroupsummary", {
                signal,
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ access_token: this.state.accessToken }),
            })
                .then(async (res1) => {
                    errorCode = res1.status;
                    let x = await res1.json();
                    return res(x);
                })
                .catch((err) => {
                    if (err.name === "AbortError") {
                        rej("AbortError");
                    }
                    if (fromRetry) {
                        // We only get here if this has errored out
                        // in which case we treat any error as a real error and not a token issue (this is to avoid endless loop)
                        rej("loginRetry");
                    } else {
                        if (errorCode == 403) {
                            // Get New Token
                            return new Promise((res1, rej) => {
                                this.getUserProfileThenRun(res1, rej).then((reg) => {
                                    res1(res(this.fetchManagementGroupsSummaryResourceGroups(signal, true)));
                                });
                            });
                        } else {
                            rej("error");
                        }
                    }
                });
        });
    };

    provisionAccount = async (marketplace_token, fromRetry) => {
        let errorCode = 200;
        let error = "";
        return new Promise((res, rej) => {
            fetch("https://api-devtest.cloudclarity.app/api/saas/landingwebhook", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    access_token: this.state.accessToken,
                    marketplace_token: marketplace_token,
                }),
            })
                .then((res) => {
                    if (res.status === 404 || res.status === 200 || res.status === 202) {
                        return res.json();
                    } else {
                        errorCode = 400;
                        return res.json();
                    }
                })
                .then(async (response) => {
                    if (errorCode == 400) {
                        error = response;
                        throw new Error(response);
                    } else {
                        await this.login();
                    }
                    return res(response);
                })

                .catch((err) => {
                    if (err.name === "AbortError") {
                        rej("AbortError");
                    }
                    if (fromRetry) {
                        // We only get here if this has errored out
                        // in which case we treat any error as a real error and not a token issue (this is to avoid endless loop)
                        rej("loginRetry");
                    } else {
                        if (errorCode == 403) {
                            // Get New Token
                            return new Promise((res1, rej) => {
                                this.getUserProfileThenRun(res1, rej).then((reg) => {
                                    res1(res(this.provisionAccount(marketplace_token, true)));
                                });
                            });
                        } else {
                            console.error(error);
                            rej(error);
                        }
                    }
                });
        });
    };

    provisionAccountAVD = async (marketplace_token, fromRetry) => {
        let errorCode = 200;
        let error = "";
        return new Promise((res, rej) => {
            fetch("https://api-devtest.cloudclarity.app/api/saas_avd/landingwebhook", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    access_token: this.state.accessToken,
                    marketplace_token: marketplace_token,
                }),
            })
                .then((res) => {
                    if (res.status === 200) {
                        return "";
                    } else {
                        errorCode = 400;
                        return res;
                    }
                })
                .then(async (response) => {
                        error = response;
                        return res(response);
                })
                .catch((err) => {
                    // console.log(err);
                    // if (err.name === "AbortError") {
                    //     rej("AbortError");
                    // }
                    // if (fromRetry) {
                    //     console.log("fromretry??");
                    //     // We only get here if this has errored out
                    //     // in which case we treat any error as a real error and not a token issue (this is to avoid endless loop)
                    //     rej("loginRetry");
                    // } else {
                    //     if (errorCode == 403) {
                    //         // Get New Token
                    //         return new Promise((res1, rej) => {
                    //             this.getUserProfileThenRun(res1, rej).then((reg) => {
                    //                 console.log("retrying???");
                    //                 res1(res(this.provisionAccountAVD(marketplace_token, true)));
                    //             });
                    //         });
                    //     } else {
                    //         console.error(error);
                    //         rej(error);
                    //     }
                    // }
                });
        });
    };

    provisionFreeAccount = (fromRetry) => {
        let errorCode = 200;
        return new Promise((res, rej) => {
            fetch("https://api-devtest.cloudclarity.app/api/saas/provisionfreeaccount", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ access_token: this.state.accessToken }),
            })
                .then(async (res1) => {
                    errorCode = res1.status;
                    let x = await res1.json();

                    return res(x);
                })
                .then((another) => {})
                .catch((err) => {
                    console.log(err);
                    if (err.name === "AbortError") {
                        rej("AbortError");
                    }
                    if (fromRetry) {
                        // We only get here if this has errored out
                        // in which case we treat any error as a real error and not a token issue (this is to avoid endless loop)
                        rej("loginRetry");
                    } else {
                        if (errorCode == 403) {
                            // Get New Token
                            return new Promise((res1, rej) => {
                                this.getUserProfileThenRun(res1, rej).then((reg) => {
                                    res1(res(this.provisionFreeAccount(true)));
                                });
                            });
                        } else {
                            rej("error");
                        }
                    }
                });
        });
    };

    getAllTagKeys = (fromRetry) => {
        let errorCode = 200;
        return new Promise((res, rej) => {
            fetch("https://api-devtest.cloudclarity.app/api/tagmanager/getalltagkeys", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    access_token: this.state.accessToken,
                }),
            })
                .then(async (res1) => {
                    errorCode = res1.status;
                    let x = await res1.json();
                    return res(x);
                })
                .catch((err) => {
                    if (err.name === "AbortError") {
                        rej("AbortError");
                    }
                    if (fromRetry) {
                        // We only get here if this has errored out
                        // in which case we treat any error as a real error and not a token issue (this is to avoid endless loop)
                        rej("loginRetry");
                    } else {
                        if (errorCode == 403) {
                            // Get New Token
                            return new Promise((res1, rej) => {
                                this.getUserProfileThenRun(res1, rej).then((reg) => {
                                    res1(res(this.getAllTagKeys(true)));
                                });
                            });
                        } else {
                            rej("error");
                        }
                    }
                });
        });
    };

    GetTagValues = (tagKey, fromRetry) => {
        let errorCode = 200;
        return new Promise((res, rej) => {
            fetch("https://api-devtest.cloudclarity.app/api/tagmanager/gettagvalues", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    access_token: this.state.accessToken,
                    tagKey,
                }),
            })
                .then(async (res1) => {
                    errorCode = res1.status;
                    let x = await res1.json();
                    return res(x);
                })
                .catch((err) => {
                    if (err.name === "AbortError") {
                        rej("AbortError");
                    }
                    if (fromRetry) {
                        // We only get here if this has errored out
                        // in which case we treat any error as a real error and not a token issue (this is to avoid endless loop)
                        rej("loginRetry");
                    } else {
                        if (errorCode == 403) {
                            // Get New Token
                            return new Promise((res1, rej) => {
                                this.getUserProfileThenRun(res1, rej).then((reg) => {
                                    res1(res(this.GetTagValues(true)));
                                });
                            });
                        } else {
                            rej("error");
                        }
                    }
                });
        });
    };

    updateDefaultFilterTags = (defaultFilterTags, fromRetry) => {
        let errorCode = 200;
        return new Promise((res, rej) => {
            fetch("https://api-devtest.cloudclarity.app/api/tagmanager/updatedefaultfiltertags", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    access_token: this.state.accessToken,
                    defaultFilterTags: defaultFilterTags,
                }),
            })
                .then(async (res1) => {
                    errorCode = res1.status;
                    let x = await res1.json();
                    return res(x);
                })
                .catch((err) => {
                    if (err.name === "AbortError") {
                        rej("AbortError");
                    }
                    if (fromRetry) {
                        // We only get here if this has errored out
                        // in which case we treat any error as a real error and not a token issue (this is to avoid endless loop)
                        rej("loginRetry");
                    } else {
                        if (errorCode == 403) {
                            // Get New Token
                            return new Promise((res1, rej) => {
                                this.getUserProfileThenRun(res1, rej).then((reg) => {
                                    res1(res(this.updateDefaultFilterTags(true)));
                                });
                            });
                        } else {
                            rej("error");
                        }
                    }
                });
        });
    };

    clearDefaultScopeTagsValuesAndGroups = (fromRetry) => {
        let errorCode = 200;
        return new Promise((res, rej) => {
            fetch("https://api-devtest.cloudclarity.app/api/tagmanager/cleardefault", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    access_token: this.state.accessToken,
                }),
            })
                .then(async (response) => {
                    if (!response.ok) {
                        errorCode = response.status;
                        throw new Error();
                    } else {
                        errorCode = response.status;
                        let idTokenClaims = {};
                        idTokenClaims.name = this.state.userName;
                        idTokenClaims.preferred_username = this.state.userEmail;
                        await this.checkLogin({
                            accessToken: this.state.accessToken,
                            idTokenClaims: idTokenClaims,
                        });
                        return res(response.json());
                    }
                })

                .catch((err) => {
                    console.log(err);
                    if (err.name === "AbortError") {
                        rej("AbortError");
                    }
                    if (fromRetry) {
                        // We only get here if this has errored out
                        // in which case we treat any error as a real error and not a token issue (this is to avoid endless loop)
                        rej("loginRetry");
                    } else {
                        if (errorCode == 403) {
                            // Get New Token
                            return new Promise((res1, rej) => {
                                this.getUserProfileThenRun(res1, rej).then((reg) => {
                                    res1(res(this.clearDefaultScopeTagsValuesAndGroups(true)));
                                });
                            });
                        } else {
                            rej("error");
                        }
                    }
                });
        });
    };

    clearOtherViews = (fromRetry) => {
        let errorCode = 200;
        return new Promise((res, rej) => {
            fetch("https://api-devtest.cloudclarity.app/api/tagmanager/clearotherviews", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    access_token: this.state.accessToken,
                }),
            })
                .then((response) => {
                    if (!response.ok) {
                        errorCode = response.status;
                        throw new Error();
                    } else {
                        errorCode = response.status;
                        let idTokenClaims = {};
                        idTokenClaims.name = this.state.userName;
                        idTokenClaims.preferred_username = this.state.userEmail;
                        this.checkLogin({
                            accessToken: this.state.accessToken,
                            idTokenClaims: idTokenClaims,
                        });
                        return res(response.json());
                    }
                })
                .catch((err) => {
                    if (err.name === "AbortError") {
                        rej("AbortError");
                    }
                    if (fromRetry) {
                        // We only get here if this has errored out
                        // in which case we treat any error as a real error and not a token issue (this is to avoid endless loop)
                        rej("loginRetry");
                    } else {
                        if (errorCode == 403) {
                            // Get New Token
                            return new Promise((res1, rej) => {
                                this.getUserProfileThenRun(res1, rej).then((reg) => {
                                    res1(res(this.clearOtherViews(true)));
                                });
                            });
                        } else {
                            rej("error");
                        }
                    }
                });
        });
    };

    getSubs = (fromRetry) => {
        let errorCode = 200;
        return new Promise((res, rej) => {
            fetch("https://api-devtest.cloudclarity.app/api/tagmanager/getsubscriptions", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    access_token: this.state.accessToken,
                }),
            })
                .then(async (res1) => {
                    errorCode = res1.status;
                    let x = await res1.json();
                    return res(x);
                })
                .catch((err) => {
                    if (err.name === "AbortError") {
                        rej("AbortError");
                    }
                    if (fromRetry) {
                        // We only get here if this has errored out
                        // in which case we treat any error as a real error and not a token issue (this is to avoid endless loop)
                        rej("loginRetry");
                    } else {
                        if (errorCode == 403) {
                            // Get New Token
                            return new Promise((res1, rej) => {
                                this.getUserProfileThenRun(res1, rej).then((reg) => {
                                    res1(res(this.getSubs(true)));
                                });
                            });
                        } else {
                            rej("error");
                        }
                    }
                });
        });
    };

    addSubsToAccount = (subsToAdd, fromRetry) => {
        let errorCode = 200;
        return new Promise((res, rej) => {
            fetch("https://api-devtest.cloudclarity.app/api/saas/addsubscriptions", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    access_token: this.state.accessToken,
                    subscriptionsToAdd: subsToAdd,
                }),
            })
                .then(async (res1) => {
                    errorCode = res1.status;
                    let x = await res1.json();
                    return res(x);
                })
                .catch((err) => {
                    if (err.name === "AbortError") {
                        rej("AbortError");
                    }
                    if (fromRetry) {
                        // We only get here if this has errored out
                        // in which case we treat any error as a real error and not a token issue (this is to avoid endless loop)
                        rej("loginRetry");
                    } else {
                        if (errorCode == 403) {
                            // Get New Token
                            return new Promise((res1, rej) => {
                                this.getUserProfileThenRun(res1, rej).then((reg) => {
                                    res1(res(this.addSubsToAccount(true)));
                                });
                            });
                        } else {
                            rej("error");
                        }
                    }
                });
        });
    };

    buildRisingCosts = (fromRetry, checkSub, subsToCheck) => {
        let errorCode = 200;
        return new Promise((res, rej) => {
            fetch("https://api-devtest.cloudclarity.app/api/dashboard/xtx/rising-costs", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    access_token: this.state.accessToken,
                    subs: this.state.elevatedSubscriptions,
                    checkSub: checkSub === undefined ? false : checkSub,
                    subsToCheck: subsToCheck === undefined ? [] : subsToCheck,
                }),
            }).then(async (res1) => {
                errorCode = res1.status;

                if (errorCode == 409) {
                    return res(409);
                }
                if (errorCode == 200) {
                    return res(200);
                }
                let x = await res1.json();
                return res(x);
            });
        });
    };

    getRisingCosts = (firstDateStart, firstDateEnd, compDateStart, compDateEnd, tag, subs, granularity, fromRetry, fullRetry) => {
        let errorCode = 200;
        return new Promise((res, rej) => {
            fetch("https://api-devtest.cloudclarity.app/api/dashboard/xtx/rising-costs/query", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    access_token: this.state.accessToken,
                    startDate: firstDateStart,
                    endDate: firstDateEnd,
                    compStartDate: compDateStart,
                    compEndDate: compDateEnd,
                    tag: tag,
                    subs: subs,
                    granularity: granularity.value,
                }),
            })
                .then(async (res1) => {
                    errorCode = res1.status;
                    let x = await res1.json();
                    return res(x);
                })
                .catch((err) => {
                    if (err.name === "AbortError") {
                        rej("AbortError");
                    }
                    if (fromRetry || fullRetry) {
                        // We only get here if this has errored out
                        // in which case we treat any error as a real error and not a token issue (this is to avoid endless loop)
                        rej("loginRetry");
                    } else {
                        if (errorCode == 403) {
                            // Get New Token
                            return new Promise((res1, rej) => {
                                this.getUserProfileThenRun(res1, rej).then((reg) => {
                                    res1(res(this.getRisingCosts(firstDateStart, firstDateEnd, compDateStart, compDateEnd, tag, subs, true, true)));
                                });
                            });
                        } else {
                            rej("error");
                        }
                    }
                });
        });
    };

    getRisingCostTypes = (firstDateStart, firstDateEnd, compDateStart, compDateEnd, newCosts, tag, subs, type, subType, granularity, fromRetry) => {
        let errorCode = 200;
        return new Promise((res, rej) => {
            fetch("https://api-devtest.cloudclarity.app/api/dashboard/xtx/rising-costs/query/type", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    access_token: this.state.accessToken,
                    startDate: firstDateStart,
                    endDate: firstDateEnd,
                    compDateStart: compDateStart,
                    compDateEnd: compDateEnd,
                    newCosts: newCosts,
                    tag: tag,
                    subs: subs,
                    type: type,
                    subType: subType,
                    granularity: granularity,
                }),
            })
                .then(async (res1) => {
                    errorCode = res1.status;
                    let x = await res1.json();
                    return res(x);
                })
                .catch((err) => {
                    if (err.name === "AbortError") {
                        rej("AbortError");
                    }
                    if (fromRetry) {
                        // We only get here if this has errored out
                        // in which case we treat any error as a real error and not a token issue (this is to avoid endless loop)
                        rej("loginRetry");
                    } else {
                        if (errorCode == 403) {
                            // Get New Token
                            return new Promise((res1, rej) => {
                                this.getUserProfileThenRun(res1, rej).then((reg) => {
                                    res1(res(this.getRisingCostTypes(firstDateStart, firstDateEnd, compDateStart, compDateEnd, newCosts, tag, subs, type, subType, granularity, true)));
                                });
                            });
                        } else {
                            rej("error");
                        }
                    }
                });
        });
    };

    risingCostClearCache = (fromRetry) => {
        let errorCode = 200;
        return new Promise((res, rej) => {
            fetch("https://api-devtest.cloudclarity.app/api/dashboard/xtx/rising-costs/clearcache", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    access_token: this.state.accessToken,
                }),
            })
                .then(async (res1) => {
                    errorCode = res1.status;
                    let x = await res1;
                    return res(x);
                })
                .catch((err) => {
                    if (err.name === "AbortError") {
                        rej("AbortError");
                    }
                    if (fromRetry) {
                        // We only get here if this has errored out
                        // in which case we treat any error as a real error and not a token issue (this is to avoid endless loop)
                        rej("loginRetry");
                    } else {
                        if (errorCode == 403) {
                            // Get New Token
                            return new Promise((res1, rej) => {
                                this.getUserProfileThenRun(res1, rej).then((reg) => {
                                    res1(res(this.risingCostClearCache(true)));
                                });
                            });
                        } else {
                            rej("error");
                        }
                    }
                });
        });
    };

    getCacheInfo = (subs, fromRetry) => {
        let errorCode = 200;
        return new Promise((res, rej) => {
            fetch("https://api-devtest.cloudclarity.app/api/dashboard/xtx/rising-costs/getcacheinfo", {
                method: "POST",
                headers: {
                    "content-Type": "application/json",
                },
                body: JSON.stringify({
                    access_token: this.state.accessToken,
                    subs: subs,
                }),
            })
                .then(async (res1) => {
                    errorCode = res1.status;
                    let x = await res1.json();
                    return res(x);
                })
                .catch((err) => {
                    if (err.name === "AbortError") {
                        rej("AbortError");
                    }
                    if (fromRetry) {
                        // We only get here if this has errored out
                        // in which case we treat any error as a real error and not a token issue (this is to avoid endless loop)
                        rej("loginRetry");
                    } else {
                        if (errorCode == 403) {
                            // Get New Token
                            return new Promise((res1, rej) => {
                                this.getUserProfileThenRun(res1, rej).then((reg) => {
                                    res1(res(this.getCacheInfo(subs, true)));
                                });
                            });
                        } else {
                            rej("error");
                        }
                    }
                });
        });
    };

    GetWorkspaces = (scopeId, scopeType, fromRetry) => {
        let errorCode = 200;
        return new Promise((res, rej) => {
            fetch("https://api-devtest.cloudclarity.app/api/dashboard/avd/getworkspaces", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    access_token: this.state.accessToken,
                    scopeId,
                    scopeType,
                }),
            })
                .then(async (res1) => {
                    errorCode = res1.status;
                    let x = await res1.json();
                    return res(x);
                })
                .catch((err) => {
                    if (err.name === "AbortError") {
                        rej("AbortError");
                    }
                    if (fromRetry) {
                        // We only get here if this has errored out
                        // in which case we treat any error as a real error and not a token issue (this is to avoid endless loop)
                        rej("loginRetry");
                    } else {
                        if (errorCode == 403) {
                            // Get New Token
                            return new Promise((res1, rej) => {
                                this.getUserProfileThenRun(res1, rej).then((reg) => {
                                    res1(res(this.GetWorkspaces(true)));
                                });
                            });
                        } else {
                            rej("error");
                        }
                    }
                });
        });
    };

    GetAVDTable = (scopeId, scopeType, avdTagKey, avdTagValue, month, year, workspaceId, logAnalyticsToken, option, granularity, fromRetry) => {
        let errorCode = 200;
        return new Promise((res, rej) => {
            fetch("https://api-devtest.cloudclarity.app/api/avd/getavdtable", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    access_token: this.state.accessToken,
                    scopeId,
                    scopeType,
                    AVDTagKey: avdTagKey,
                    AVDTagValue: avdTagValue,
                    month,
                    year,
                    workspaceId,
                    logAnalyticsToken,
                    option,
                    granularity,
                }),
            })
                .then(async (res1) => {
                    errorCode = res1.status;
                    let x = await res1.json();
                    return res(x);
                })
                .catch((err) => {
                    if (err.name === "AbortError") {
                        rej("AbortError");
                    }
                    if (fromRetry) {
                        // We only get here if this has errored out
                        // in which case we treat any error as a real error and not a token issue (this is to avoid endless loop)
                        rej("loginRetry");
                    } else {
                        if (errorCode == 403) {
                            // Get New Token
                            return new Promise((res1, rej) => {
                                this.getUserProfileThenRun(res1, rej).then((reg) => {
                                    res1(res(this.GetAVDTable(true)));
                                });
                            });
                        } else {
                            rej("error");
                        }
                    }
                });
        });
    };

    BuildAVDData = (month, year, logAnalyticsToken, granularity, configurationId, fromRetry) => {
        let errorCode = 200;
        return new Promise((res, rej) => {
            fetch("https://api-devtest.cloudclarity.app/api/avd/buildavddata", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization":"Bearer "+this.state.accessToken 
                },
                body: JSON.stringify({
                    month,
                    year,
                    logAnalyticsToken,
                    granularity,
                    configurationId,
                }),
            })
                .then(async (res1) => {
                    return res("ok");
                })
                .catch((err) => {
                    if (err.name === "AbortError") {
                        rej("AbortError");
                    }
                    if (fromRetry) {
                        // We only get here if this has errored out
                        // in which case we treat any error as a real error and not a token issue (this is to avoid endless loop)
                        rej("loginRetry");
                    } else {
                        if (errorCode == 403) {
                            // Get New Token
                            return new Promise((res1, rej) => {
                                this.getUserProfileThenRun(res1, rej).then((reg) => {
                                    res1(res(this.BuildAVDData(true)));
                                });
                            });
                        } else {
                            rej("error");
                        }
                    }
                });
        });
    };

    GetAVDFile = (configurationId, granularity, year, month, file, fromRetry) => {
        let errorCode = 200;
        return new Promise((res, rej) => {
            fetch("https://api-devtest.cloudclarity.app/api/avd/getavdfile", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    access_token: this.state.accessToken,
                    configurationId,
                    granularity,
                    year,
                    month,
                    file,
                }),
            })
                .then(async (res1) => {
                    errorCode = res1.status;
                    let x = await res1.json();
                    return res(x);
                })
                .catch((err) => {
                    if (err.name === "AbortError") {
                        rej("AbortError");
                    }
                    if (fromRetry) {
                        // We only get here if this has errored out
                        // in which case we treat any error as a real error and not a token issue (this is to avoid endless loop)
                        rej("loginRetry");
                    } else {
                        if (errorCode == 403) {
                            // Get New Token
                            return new Promise((res1, rej) => {
                                this.getUserProfileThenRun(res1, rej).then((reg) => {
                                    res1(res(this.GetAVDFile(true)));
                                });
                            });
                        } else {
                            rej("error");
                        }
                    }
                });
        });
    };

    GetFile = (configurationId, year, month, file, pageNumber, fromRetry) => {
        let errorCode = 200;

        return new Promise((res, rej) => {
            fetch("https://api-devtest.cloudclarity.app/api/avd/getfile?pageNumber=" + pageNumber, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + this.state.accessToken
                },
                body: JSON.stringify({
                    configurationId,
                    year,
                    month,
                    file,
                }),
            })
                .then(async (res1) => {
                    errorCode = res1.status;
                    let x = await res1.json();
                    return res(x);
                })
                .catch((err) => {
                    if (err.name === "AbortError") {
                        rej("AbortError");
                    }
                    if (fromRetry) {
                        // We only get here if this has errored out
                        // in which case we treat any error as a real error and not a token issue (this is to avoid endless loop)
                        rej("loginRetry");
                    } else {
                        if (errorCode == 403) {
                            // Get New Token
                            return new Promise((res1, rej) => {
                                this.getUserProfileThenRun(res1, rej).then((reg) => {
                                    res1(res(this.GetFile(true)));
                                });
                            });
                        } else {
                            rej("error");
                        }
                    }
                });
        });
    };

    UpdateStorageSettings = (sasToken, storageAccountName) => {
        let errorCode = 200;
        return new Promise((res, rej) => {
            fetch("https://api-devtest.cloudclarity.app/api/settings/updatestorageaccount", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    accessToken: this.state.accessToken,
                    sasToken,
                    storageAccountName,
                }),
            }).catch((err) => {});
        });
    };

    GetCostExplorer = (scope, startDateRange_1, endDateRange_1, startDateRange_2, endDateRange_2, groupBy, fromRetry) => {
        let errorCode = 200;
        return new Promise((res, rej) => {
            fetch("https://api-devtest.cloudclarity.app/api/dashboard/getcostexplorer", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    access_token: this.state.accessToken,
                    scope,
                    startDateRange_1,
                    endDateRange_1,
                    startDateRange_2,
                    endDateRange_2,
                    groupBy,
                }),
            })
                .then(async (res1) => {
                    errorCode = res1.status;
                    let x = await res1.json();
                    return res(x);
                })
                .catch((err) => {
                    console.log(err.message);
                    if (err.name === "AbortError") {
                        rej("AbortError");
                    }
                    if (fromRetry) {
                        // We only get here if this has errored out
                        // in which case we treat any error as a real error and not a token issue (this is to avoid endless loop)
                        rej("loginRetry");
                    } else {
                        if (errorCode == 403) {
                            // Get New Token
                            return new Promise((res1, rej) => {
                                this.getUserProfileThenRun(res1, rej).then((reg) => {
                                    res1(res(this.GetCostExplorer(scope, startDateRange_1, endDateRange_1, startDateRange_2, endDateRange_2, groupBy, true)));
                                });
                            });
                        } else {
                            rej("error");
                        }
                    }
                });
        });
    };

    componentDidMount() {
        // If MSAL already has an account, the user
        // is already logged in
        // dont run this if pathname is order - as this creates a race condition
        //if (window.location.pathname.indexOf("/order") == -1 || window.location.pathname == "/order_avd") {
            userAgentApplication
                .handleRedirectPromise()
                .then(this.handleResponse)
                .catch((err) => {
                    console.error(err);
                });

            this.setState({ isLoading: false });
        //}
    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }

    render() {
        return (
            <AppContext.Provider
                value={{
                    state: this.state,
                    tryLogin: () => this.login(),
                    logout: () => this.logout(),
                    renew: () => {
                        return new Promise((res, rej) => {
                            this.getUserProfileThenRun(res, rej);
                        });
                    },
                    resetLoginRetry: (subId) => {
                        this.resetLoginRetry1();
                    },
                    getCostEditView: (tagKey, subscriptionId, fromRetry) => this.getCostEditView(tagKey, subscriptionId, fromRetry),
                    sendCostUpdateTags: (resources, versionHistoryEntry, allChanges, fromRetry) => this.sendCostUpdateTags(resources, versionHistoryEntry, allChanges, fromRetry),
                    sendCostDeleteTags: (Ids, subscriptionId, tagKey, fromRetry) => this.sendCostDeleteTags(Ids, subscriptionId, tagKey, fromRetry),
                    sendUpdateTags: (versionHistory, resources, operation, allChanges, fromRetry) => this.sendUpdateTags(versionHistory, resources, operation, allChanges, fromRetry),
                    sendUpdateTagsDelete: (tagKeys, type, subscriptionId, versionHistoryEntry, tagsToDelete, fromRetry) =>
                        this.sendUpdateTagsDelete(tagKeys, type, subscriptionId, versionHistoryEntry, tagsToDelete, fromRetry),

                    costSummaryTrigger: (subscriptionId, context, type, fromRetry) => this.costSummaryTrigger(subscriptionId, context, type, fromRetry),

                    updateCM: (subscriptionId, context, type, fromRetry, selectedTags) => this.updateCM(subscriptionId, context, type, fromRetry, selectedTags),
                    updateSelectedTags: (selectedTags) => this.updateSelectedTags(selectedTags),

                    setActiveSub: (activeSub) => this.setActiveSub(activeSub),
                    getTagInfo: (tag, subId, fromRetry, mode) => this.getTagInfo(tag, subId, fromRetry, mode),
                    setManagementGroupTags: (versionHistoryEntry, resources, allChanges, fromRetry) => this.setManagementGroupTags(versionHistoryEntry, resources, allChanges, fromRetry),
                    setManagementGroupRGTags: (resources, fromRetry) => this.setManagementGroupRGTags(resources, fromRetry),
                    fetchManagementGroups: (signal, fromRetry) => this.fetchManagementGroups(signal, fromRetry),
                    fetchManagementGroupsSummary: (signal, fromRetry, hierarchy) => this.fetchManagementGroupsSummary(signal, fromRetry, hierarchy),
                    getAllResourceTags: (signal, subscriptionId, fromRetry) => this.getAllResourceTags(signal, subscriptionId, fromRetry),
                    getScopedCosts: (signal, includedSubs, scopeType, scopeId, tagKeys, fromGraph, fromRetry) =>
                        this.getScopedCosts(signal, includedSubs, scopeType, scopeId, tagKeys, fromGraph, fromRetry),
                    getDashboardCostSummary: (signal, includedSubs, scopeType, scopeId, tagKeys, tagKey, fromRetry) =>
                        this.getDashboardCostSummary(signal, includedSubs, scopeType, scopeId, tagKeys, tagKey, fromRetry),
                    getExportResourcesDashboard: (signal, includedSubs, scopeType, scopeId, tagKeys, tagValues, notTagged, groupBy, fromRetry) =>
                        this.getExportResourcesDashboard(signal, includedSubs, scopeType, scopeId, tagKeys, tagValues, notTagged, groupBy, fromRetry),
                    getTiles: (signal, includedSubs, scopeType, scopeId, tagKeys, tagValues, fromRetry) => this.getTiles(signal, includedSubs, scopeType, scopeId, tagKeys, tagValues, fromRetry),
                    getSuggestions: (signal, includedSubs, scopeType, scopeId, tagKeys, tagValues, fromRetry) =>
                        this.getSuggestions(signal, includedSubs, scopeType, scopeId, tagKeys, tagValues, fromRetry),

                    updatePreferences: (defaultScope, defaultTag, defaultValue, defaultGroups, otherScopes, fromRetry) =>
                        this.updatePreferences(defaultScope, defaultTag, defaultValue, defaultGroups, otherScopes, fromRetry),
                    fetchResource: (subscriptionId, signal, fromRetry) => this.fetchResource(subscriptionId, signal, fromRetry),

                    fetchResourceGroups: (signal, fromRetry, id) => this.fetchResourceGroups(signal, fromRetry, id),
                    fetchResourceGroupsLegacy: (signal, fromRetry, id) => this.fetchResourceGroupsLegacy(signal, fromRetry, id),
                    fetchManagementGroupsSummaryResourceGroups: (signal, fromRetry) => this.fetchManagementGroupsSummaryResourceGroups(signal, fromRetry),
                    provisionAccount: (marketplace_token, fromRetry) => this.provisionAccount(marketplace_token, fromRetry),
                    provisionAccountAVD: (marketplace_token, fromRetry) => this.provisionAccountAVD(marketplace_token, fromRetry),
                    provisionFreeAccount: (fromRetry) => this.provisionFreeAccount(fromRetry),
                    getScopedResources: (signal, includedSubs, tagKey, subscriptionId, scopeType, scopeId, tagKeys, tagValues, notTagged, fromRetry) =>
                        this.getScopedResources(signal, includedSubs, tagKey, subscriptionId, scopeType, scopeId, tagKeys, tagValues, notTagged, fromRetry),
                    getDashboardCostEditView: (signal, includedSubs, tagKey, subscriptionId, scopeType, scopeId, tagKeys, tagValues, notTagged, fromRetry) =>
                        this.getDashboardCostEditView(signal, includedSubs, tagKey, subscriptionId, scopeType, scopeId, tagKeys, tagValues, notTagged, fromRetry),
                    getTagHealthReport: (signal, includedSubs, scopeType, scopeId, tagKeys, tagValues, keysToValidate, fromRetry) =>
                        this.getTagHealthReport(signal, includedSubs, scopeType, scopeId, tagKeys, tagValues, keysToValidate, fromRetry),
                    savePreferences: (signal, defaultValidationTags, otherValidationTags, fromRetry) => this.savePreferences(signal, defaultValidationTags, otherValidationTags, fromRetry),
                    getMGExportData: (signal, allScopes, selectedScope, includedSubs, tagKeys, tagValues, tagsToValidate, fromRetry) =>
                        this.getMGExportData(signal, allScopes, selectedScope, includedSubs, tagKeys, tagValues, tagsToValidate, fromRetry),
                    getCostTable: (subId, viewType, signal, fromRetry, groupBy) => this.getCostTable(subId, viewType, signal, fromRetry, groupBy),
                    getCostTableResources: (subId, viewType, fromRetry, groupBy, scope) => this.getCostTableResources(subId, viewType, fromRetry, groupBy, scope),
                    getCostTableResourcesDashboard: (includedSubs, scopeType, scopeId, tagKeys, tagValues, notTagged, groupBy, scope, fromRetry) =>
                        this.getCostTableResourcesDashboard(includedSubs, scopeType, scopeId, tagKeys, tagValues, notTagged, groupBy, scope, fromRetry),
                    getSubs: (fromRetry) => this.getSubs(fromRetry),
                    getAllTagKeys: (fromRetry) => this.getAllTagKeys(fromRetry),
                    addSubsToAccount: (subs, fromRetry) => this.addSubsToAccount(subs, fromRetry),
                    updateDefaultFilterTags: (defaultFilterTags, fromRetry) => this.updateDefaultFilterTags(defaultFilterTags, fromRetry),
                    clearDefaultScopeTagsValuesAndGroups: (fromRetry) => this.clearDefaultScopeTagsValuesAndGroups(fromRetry),
                    clearOtherViews: (fromRetry) => this.clearOtherViews(fromRetry),
                    getRisingCosts: (firstDateStart, firstDateEnd, compDateStart, compDateEnd, tag, subs, granularity, fromRetry) =>
                        this.getRisingCosts(firstDateStart, firstDateEnd, compDateStart, compDateEnd, tag, subs, granularity, fromRetry),
                    getRisingCostTypes: (firstDateStart, firstDateEnd, compDateStart, compDateEnd, newCosts, tag, subs, type, subType, granularity, fromRetry) =>
                        this.getRisingCostTypes(firstDateStart, firstDateEnd, compDateStart, compDateEnd, newCosts, tag, subs, type, subType, granularity, fromRetry),
                    risingCostClearCache: (fromRetry) => this.risingCostClearCache(fromRetry),
                    getCacheInfo: (subs, fromRetry) => this.getCacheInfo(subs, fromRetry),
                    buildRisingCosts: (fromRetry, checkSub, subsToCheck) => this.buildRisingCosts(fromRetry, checkSub, subsToCheck),
                    GetWorkspaces: (scopeId, scopeType, fromRetry) => this.GetWorkspaces(scopeId, scopeType, fromRetry),
                    FetchLogAnalyticsToken: () => this.FetchLogAnalyticsToken(),
                    GetAVDTable: (scopeId, scopeType, avdTagKey, avdTagValue, month, year, workspaceId, logAnalyticsToken, fromRetry) =>
                        this.GetAVDTable(scopeId, scopeType, avdTagKey, avdTagValue, month, year, workspaceId, logAnalyticsToken, fromRetry),
                    BuildAVDData: (scopeId, scopeType, avdTagKey, avdTagValue, month, year, workspaceId, logAnalyticsToken, option, granularity, configurationId, fromRetry) =>
                        this.BuildAVDData(scopeId, scopeType, avdTagKey, avdTagValue, month, year, workspaceId, logAnalyticsToken, option, granularity, configurationId, fromRetry),
                    GetAVDFile: (configurationId, granularity, year, month, file, fromRetry) => this.GetAVDFile(configurationId, granularity, year, month, file, fromRetry),
                    GetFile: (configurationId, year, month, file, pageNumber, fromRetry) => this.GetFile(configurationId, year, month, file, pageNumber, fromRetry),
                    GetTagValues: (tagKey, fromRetry) => this.GetTagValues(tagKey, fromRetry),
                    GetCostExplorer: (scope, startDateRange_1, endDateRange_1, startDateRange_2, endDateRange_2, groupBy, fromRetry) =>
                        this.GetCostExplorer(scope, startDateRange_1, endDateRange_1, startDateRange_2, endDateRange_2, groupBy, fromRetry),
                    getChargebackResources: (includedSubs, scopeType, scopeId, tagKey, tagValue, fromRetry) =>
                        this.getChargebackResources(includedSubs, scopeType, scopeId, tagKey, tagValue, fromRetry),
                    UpdateStorageSettings: (sasToken, storageAccountName) => this.UpdateStorageSettings(sasToken, storageAccountName),
                    getDBResourcesCost: (accessToken, subId) => this.getDBResourcesCost(accessToken, subId),
                }}
            >
                {this.props.children}
            </AppContext.Provider>
        );
    }
}

const RootElement = () => {
    return (
        <ErrorBoundary>
            <ModernProvider>
                <React.Fragment>
                    <ErrorBoundary>
                        <ToastContainer
                            position="bottom-right"
                            autoClose={false}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            style={{ zIndex: 999 }}
                        />
                        <App />
                    </ErrorBoundary>
                </React.Fragment>
            </ModernProvider>
        </ErrorBoundary>
    );
};
const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
    ReactDOM.hydrate(<RootElement />, rootElement);
} else {
    ReactDOM.render(<RootElement />, rootElement);
}

// ReactDOM.render(<RootElement />, document.getElementById("root"));
