let dropdownStylesDashDisabled = {
    singleValue: (base) => ({ ...base, color: "#ffffff", background: "#39393d" }),
    multiValue: (base) => ({ ...base, color: "#ffff", background: "#387196", borderRadius: "4px", fontSize: "12px" }),
    multiValueLabel: (base) => ({ color: "#ffffff", borderRadius: "4px", padding: "2px 6px", fontSize: "11px", fontWeight: "500" }),
    dropdownIndicator: (base) => ({ ...base, background: "#39393d" }),
    indicatorsContainer: (base) => ({ ...base, background: "#39393d" }),
    container: (base) => ({ ...base, color: "#b9b9bb", background: "#39393d", border: "#757575" }),
    menu: (base) => ({ ...base, color: "#b9b9bb", background: "#39393d", border: "#757575", zIndex: 2 }),
    control: (base) => ({ ...base, color: "#b9b9bb", background: "#39393d", border: "#757575 1px solid" }),
    valueContainer: (base) => ({
        ...base,
        background: "#39393d",
        color: "##b9b9bb",
        width: "100%",
    }),
    option: (base) => ({ ...base, color: "#b9b9bb" }),
    indicatorSeparator: (base) => ({ ...base, background: "#39393d" }),
    input: (base) => ({ ...base, color: "#ffffff" }),
};

export default dropdownStylesDashDisabled;
