import React from "react";

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = {error: null, errorInfo: null};
	}

	componentDidCatch(error, errorInfo) {
		// Catch errors in any components below and re-render with error message
		this.setState({
			error: error,
			errorInfo: errorInfo
		});
		// You can also log error messages to an error reporting service here
	}

	reload = () => {
		window.location.reload();
	};

	reloadHome = () => {
		window.location.href = "/";
	};

	render() {
		if (this.state.errorInfo) {
			// Error path
			return (
				<div>
					<div className="error-message new">
						<svg id="umbrella-rain" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" style={{margin: "0 0!important"}}>
							<defs>
								<style
									dangerouslySetInnerHTML={{
										__html:
											".cls-1{fill:#61c5a8;}.cls-1,.cls-2,.cls-3,.cls-6{stroke:#54596e;stroke-linecap:round;stroke-width:2px;}.cls-1,.cls-2,.cls-3{stroke-linejoin:round;}.cls-2,.cls-5{fill:#54596e;}.cls-3{fill:#fff35f;}.cls-4{opacity:0.2;}.cls-6{fill:none;stroke-miterlimit:10;}.cls-7{fill:#55a6ff;}"
									}}
								/>
							</defs>
							<title>umbrella-rain</title>
							<path
								className="cls-1"
								d="M6.51,54.71a7.52,7.52,0,0,1,0-10.61l.36-.35a2,2,0,0,1,2.82,2.83l-.35.35a3.5,3.5,0,0,0,4.95,5L32.2,34A2,2,0,0,1,35,36.8L17.12,54.71A7.52,7.52,0,0,1,6.51,54.71Z"
							/>
							<circle className="cls-2" cx={52} cy={17} r={2} />
							<path
								className="cls-3"
								d="M19.83,22.3c3.13,3.14,5,6.56,4.89,8.55,2-.14,5.41,1.75,8.54,4.89s5,6.56,4.89,8.54c2-.13,5.41,1.76,8.55,4.89,3.74,3.75,5.71,7.9,4.57,9.53l0,0a29,29,0,0,0-41-41l0,0C11.93,16.59,16.08,18.56,19.83,22.3Z"
							/>
							<g className="cls-4">
								<path className="cls-5" d="M10.49,17.51l-.21.2,0,0,.26-.14Z" />
								<path
									className="cls-5"
									d="M51.29,17.71c-.6-.61-1.23-1.17-1.87-1.71a33.45,33.45,0,0,1-.67,35.49c2.36,3,3.42,5.91,2.52,7.21l0,0A29,29,0,0,0,51.29,17.71Z"
								/>
							</g>
							<path className="cls-6" d="M41.1,23.66a3,3,0,1,0,4.24,4.24" />
							<path className="cls-6" d="M51,33.56a3,3,0,0,0,4.24,4.24" />
							<path className="cls-6" d="M47,41.78a11.61,11.61,0,0,0-3.27-5.29c-2-2-4.17-3.19-5.76-3.33" />
							<path className="cls-7" d="M31.34,17.88a3.22,3.22,0,0,1-6.43,0c0-1.78,2.57-5.79,3.21-5.79S31.34,16.1,31.34,17.88Z" />
							<path className="cls-7" d="M55.34,27.88a3.22,3.22,0,0,1-6.43,0c0-1.78,2.57-5.79,3.21-5.79S55.34,26.1,55.34,27.88Z" />
							<path className="cls-7" d="M39.34,10.88a3.22,3.22,0,0,1-6.43,0c0-1.78,2.57-5.79,3.21-5.79S39.34,9.1,39.34,10.88Z" />
							<path className="cls-7" d="M61.34,8.88a3.22,3.22,0,0,1-6.43,0c0-1.78,2.57-5.79,3.21-5.79S61.34,7.1,61.34,8.88Z" />
						</svg>

						<h2>uh oh! Something Major went wrong.</h2>
						<details style={{whiteSpace: "pre-wrap"}}>
							{this.state.error && this.state.error.toString()}
							<br />
							{this.state.errorInfo.componentStack}
						</details>
						<br />
						<div style={{display: "flex"}}>
							<button className="button ui button__component" onClick={this.reload} style={{margin: "16px 16px 16px"}}>
								Reload page
							</button>
							<button className="button ui button__component" onClick={this.reloadHome} style={{margin: "16px 16px 16px"}}>
								Go to homepage
							</button>
						</div>
					</div>
				</div>
			);
		}
		// Normally, just render children
		return this.props.children;
	}
}

export default ErrorBoundary;
