import React, { useState } from "react";
import { Panel,PanelType, FontIcon, mergeStyles, MessageBar } from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";
export const NotificationCenterHandle = () => {
    const [isPanelOpen, setIsPanelOpen] = useState(false);  
    const titleId = useId("notificationPanelTitle");

    const openPanel = () => {
        setIsPanelOpen(true);
    }
    const closePanel = () => {
        setIsPanelOpen(false);
    }
    return (
        <>
            <FontIcon title="Notifications" aria-label="Notifications" iconName="Ringer" className={iconClass} onClick={openPanel} />
            <Panel
                headerText="Notifications"
                isOpen={isPanelOpen}
                isBlocking={false}
                onDismiss={closePanel}
                type={PanelType.smallFixedFar}
                closeButtonAriaLabel="Close"
            >
                <p>Content goes here.</p>
            </Panel>
        </>
    )
}

const iconClass = mergeStyles({
    fontSize: 18,
    cursor: 'pointer',
    marginRight: 10
})