import React, { useState, useContext, useEffect, Fragment, useRef } from "react";
import AppContext from "../../utils/AppContext";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import CommonPageLayout from "components/page-layout/CommonPageLayout";
import { Loader } from "semantic-ui-react";
import "./order.css";

const Order = ({avd}) => {
    let context = useContext(AppContext);
    const [provisioned, setProvisioned] = useState("false");
    const [errorMessage, setErrorMessage] = useState("");
    
    useEffect(() => {
        if (provisioned !== "true" && provisioned !== "error" && navigator.userAgent != "ReactSnap") {
            logoutThenProvision();
        }
    }, []);

    const logoutThenProvision = async () => {
        const urlParams = new URLSearchParams(window.location.search);
        
        if(avd === true) {
            context
            .provisionAccountAVD(urlParams.get("token"), false)
            .then((res) => {
                setProvisioned("true");
            })
            .catch((err) => {
                setProvisioned("error");
                setErrorMessage(err);
            });
        } else {
            context
                .provisionAccount(urlParams.get("token"), false)
                .then((res) => {
                    if (res.indexOf("Error") !== -1) {
                        setProvisioned("error");
                    } else {
                        if (res.indexOf("There is already an unexpired plan") !== -1) {
                            setProvisioned("provisioned");
                        } else {
                            setProvisioned("true");
                        }
                    }
                })
                .catch((err) => {
                    setProvisioned("error");
                    setErrorMessage(err);
                });
        }
    };

    let finalError = "";
    if (typeof errorMessage == "string") {
        finalError = errorMessage;
    }

    return (
        <>
            <CommonPageLayout className="main-landing-page__component">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>CloudClarity | order</title>
                </Helmet>
                <div id="pricing">
                    {provisioned == "false" && (
                        <Fragment>
                            <Loader active={true} />{" "}
                            <h3 style={{ textAlign: "center", position: "absolute", top: "53%", left: "50%", transform: "translate(-50%, -50%)" }}>
                                Thank you for your order, we're provisioning your account
                            </h3>
                        </Fragment>
                    )}
                    
                    {provisioned == "error" && (
                        <Fragment>
                            <h3 style={{ textAlign: "center", position: "absolute", top: "53%", left: "50%", transform: "translate(-50%, -50%)" }}>Error Provisioning Account. Error: {finalError}</h3>
                        </Fragment>
                    )}
                    {provisioned == "true" && (
                        <Fragment>
                            <h3 style={{ textAlign: "center", position: "absolute", top: "53%", left: "50%", transform: "translate(-50%, -50%)" }}>
                                Your Account has been successfully Provisioned!
                                <br />
                                {context.state.isAuthenticated && (
                                    <Fragment>
                                        <Link to="/dashboard">Go to Dashboard</Link>
                                    </Fragment>
                                )}
                                {!context.state.isAuthenticated && <Fragment> Please Log In</Fragment>}
                            </h3>
                        </Fragment>
                    )}
                    {provisioned == "provisioned" && (
                        <Fragment>
                            <h3 style={{ textAlign: "center", position: "absolute", top: "53%", left: "50%", transform: "translate(-50%, -50%)" }}>
                                Your Account was previously provisioned.
                                <br />
                                {context.state.isAuthenticated && (
                                    <Fragment>
                                        <Link to="/dashboard">Go to Dashboard</Link>
                                    </Fragment>
                                )}
                                {!context.state.isAuthenticated && <Fragment> Please Log In</Fragment>}
                            </h3>
                        </Fragment>
                    )}
                </div>
            </CommonPageLayout>
        </>
    );
};

export default Order;
