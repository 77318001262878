import React, { useState, useContext, useEffect, Fragment, ref, useRef } from "react";
import CreatableSelect from "react-select/creatable";
import "./DefaultTagFilterSelector.css";
import AppContext from "../../../../utils/AppContext";
import dropDownStyles from "../../../../utils/dropdownStyles";
import dropdownStylesDashDisabled from "../../../../utils/dropdownStylesDashDisabled";
import { Modal } from "semantic-ui-react";

const DefaultTagFilterSelector = (props) => {
    const context = useContext(AppContext);

    const defaultTags = context.state.defaultFilterTags;
    let defaultSelected = [];
    if (defaultTags !== "null" && defaultTags !== undefined) {
        defaultTags.forEach((tag) => {
            defaultSelected.push({ label: tag, value: tag });
        });
    }
    const portalPlacement = "auto";
    const isFixed = true;

    const [selected, setSelected] = useState(defaultSelected);
    const [options, setOptions] = useState(null);
    const [isOpen, setIsOpen] = useState(null);
    const [loading, setLoading] = useState("loaded");

    const [getTagKeyError, setGetTagKeyError] = useState(false);

    const getTagKeys = () => {
        context
            .getAllTagKeys(false)
            .then((res) => {
                let objectOptions = [];
                objectOptions.push({ label: "Select All", value: "AFP_ALL_KEYS" });
                res.forEach((option) => {
                    objectOptions.push({ label: option, value: option });
                });
                setOptions(objectOptions);
                setGetTagKeyError(false);
            })
            .catch((err) => {
                setGetTagKeyError(true);
            });
    };

    const open = () => {
        console.log("menuPortal is Open");
        setLoading("loaded");
        setIsOpen(true);
    };
    const close = () => {
        setIsOpen(false);
        setLoading("loaded");
    };

    useEffect(() => {
        getTagKeys();
    }, []);

    const updateDefaultFilterTags = (e) => {
        let isSelectAll = false;
        if (e) {
            e.forEach((selectedKey) => {
                if (selectedKey.value === "AFP_ALL_KEYS") {
                    isSelectAll = true;
                }
            });
        }
        //if clicked on "select all"
        if (isSelectAll) {
            var allSelected = JSON.parse(JSON.stringify(options));
            //dont include the "select all" option as a selected key
            allSelected.splice(0, 1);
            setSelected(allSelected);
        } else {
            setSelected(e);
        }
    };

    const saveDefault = () => {
        setLoading("loading");
        let tagsToSave = [];
        if (selected && selected.length) {
            selected.forEach((selectedTag) => {
                tagsToSave.push(selectedTag.value);
            });
        }
        context
            .updateDefaultFilterTags(tagsToSave, false)
            .then(() => {
                setLoading("finished");
                window.location.reload();
            })
            .catch((err) => {
                console.log(err);
                window.location.reload();
            });
    };
    return (
        <Modal
            basic
            onClose={() => close()}
            onOpen={() => open()}
            open={isOpen}
            size="small"
            trigger={
                <button class="ui blue basic inverted button" style={{ marginTop: "8px" }}>
                    Configure Default Tags
                </button>
            }
        >
            <Fragment>
                {loading == "loaded" && (
                    <Fragment>
                        <CreatableSelect
                            className="tag-dropdown"
                            options={options}
                            styles={options === null || context.state.accountType === "" ? dropdownStylesDashDisabled : dropDownStyles}
                            isMulti
                            onChange={(e) => {
                                updateDefaultFilterTags(e);
                            }}
                            placeholder={"Default Tag Keys"}
                            isLoading={options === null}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                    ...theme.colors,
                                    primary25: "#3376cd",
                                },
                            })}
                            isClearable={true}
                            value={getTagKeyError ? "error loading tag keys, please try again." : selected}
                            isDisabled={context.state.accountType === ""}
                            menuPortalTarget={document.body}
                            isSearchable
                            name="color"
                            menuPosition={"fixed"}
                            menuPlacement={portalPlacement}
                            menuShouldScrollIntoView={false}
                        />
                        <button class="ui blue basic inverted button" onClick={() => saveDefault()} style={{ marginTop: "8px" }}>
                            <i className="save icon"></i>
                            Update Default Tags to Selection
                        </button>
                    </Fragment>
                )}
                {loading == "loading" && <h3>Updating ...</h3>}
                {loading == "finished" && <h3>Updated. Click anywhere to close</h3>}
            </Fragment>
        </Modal>
    );
};

export default DefaultTagFilterSelector;
