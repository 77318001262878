import React, { createContext } from 'react';

export const NotificationCenterContext = createContext();

export const NotificationCenterProvider = ({ children }) => {
  //you can call push notification from anywhere in the app to add a notification
  //for now this is a dummy that just displays an alert
  const pushNotification = (message) => {
    alert(`Notification: ${message}`);
  };

  return (
    <NotificationCenterContext.Provider value={{ pushNotification }}>
      {children}
    </NotificationCenterContext.Provider>
  );
};