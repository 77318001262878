import React, { Component } from "react";
import NProgress from "nprogress";
import RouteSwitch from "../../RouteSwitch";

import "./App.css";
import "nprogress/nprogress.css";
import AppContext from "../../utils/AppContext";
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import { NotificationCenterProvider } from "../common-plus/NotificationCenter.jsx";
import resourceGroupSvg from "../../icons/ic_resource_group.svg";
import { registerIcons } from "@fluentui/react";
import { GenerateResourceIconsObject } from "../../utils/GenerateResourceIconsObject.js";

const resourceIcons = GenerateResourceIconsObject();
registerIcons({
	icons: resourceIcons
});

initializeIcons();


class App extends Component {
	componentDidUpdate() {
		NProgress.start();
		if (this.context.state.isLoading) {
			NProgress.start();
		} else {
			NProgress.done();
		}
	}

	componentWillUnmount() {
		NProgress.done();
	}

	render() {
		return (
			<NotificationCenterProvider>
				<RouteSwitch />
			</NotificationCenterProvider>
		);
	};
}

export default App;
App.contextType = AppContext;

