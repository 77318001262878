import React, { Fragment } from "react";
import PropTypes from "prop-types";

import "./TableActionItem.css";

const TableActionItem = ({ actionName, icon, onClick, disabled, title }) => {
    return (
        <div className={`table-action-item__component ${disabled ? "disabled" : ""}`} onClick={disabled ? null : onClick} role="button" tabIndex={-1} title={title || null}>
            {icon == "edit outline" && (
                <Fragment>
                    <i className="edit outline icon edit-icon" />
                    <div className="action-name">{actionName}</div>
                </Fragment>
            )}

            {icon == "tag" && (
                <Fragment>
                    <i className=" icon tag hide-mobile" style={{ marginRight: "0px", color: "white" }} />
                    <div className="action-name">{actionName}</div>
                </Fragment>
            )}
            {icon == "dashboard" && (
                <Fragment>
                    <i className=" icon dashboard hide-mobile" style={{ marginRight: "0px", color: "white" }} />
                    <div className="action-name">{actionName}</div>
                </Fragment>
            )}
            {icon == "chart line" && (
                <Fragment>
                    <i className=" icon chart line hide-mobile" style={{ marginRight: "0px", color: "white" }} />
                    <div className="action-name">{actionName}</div>
                </Fragment>
            )}
            {icon == "medium" && (
                <Fragment>
                    <i className="icon medium hide-mobile" style={{ marginRight: "2px", color: "white" }} />
                    <div className="action-name">{actionName}</div>
                </Fragment>
            )}
            {icon == "microsoft" && (
                <Fragment>
                    <i className="icon microsoft hide-mobile" style={{ marginRight: "2px", color: "white" }} />
                    <div className="action-name">{actionName}</div>
                </Fragment>
            )}
            {icon == "request" && (
                <Fragment>
                    <i className="icon request hide-mobile" style={{ marginRight: "2px", color: "white" }} />
                    <div className="action-name">{actionName}</div>
                </Fragment>
            )}
            {icon == "dollar sign" && (
                <Fragment>
                    <i className="icon dollar sign hide-mobile" style={{ marginRight: "2px", color: "white" }} />
                    <div className="action-name">{actionName}</div>
                </Fragment>
            )}
            {icon == "compass" && (
                <Fragment>
                    <i className="icon compass outline hide-mobile" style={{ marginRight: "2px", color: "white" }} />
                    <div className="action-name">{actionName}</div>
                </Fragment>
            )}
            {icon != "edit outline" &&
                icon !== "microsoft" &&
                icon !== "dollar sign" &&
                icon !== "tag" &&
                icon !== "chart line" &&
                icon !== "medium" &&
                icon !== "dashboard" &&
                icon !== "request" &&
                icon !== "compass" && (
                    <Fragment>
                        <i className={`icon ${icon} hide-mobile`} style={{ fontSize: "20px", color: "white" }} />
                        <div className="action-name">{actionName}</div>
                    </Fragment>
                )}
        </div>
    );
};

TableActionItem.propTypes = {
    onClick: PropTypes.func,
    actionName: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    title: PropTypes.string,
};
TableActionItem.defaultProps = {
    onClick: null,
    disabled: false,
    title: null,
};

export default TableActionItem;
