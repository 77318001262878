import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import onClickOutside from 'react-onclickoutside';

import './HelpHamburgerMenu.css';


class HelpHamburgerMenu extends Component {

  render() {
    return (
 
      <div onClick={this.props.togglePopup} className={`hamburger-menu`}>
        <div
          className="icon"
          id="icon"
        />

      </div>
    
    );
  }
}

export default onClickOutside(HelpHamburgerMenu);