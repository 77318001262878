import React, { useContext, lazy, Suspense, Fragment, useState } from "react";
import AppContext from "./utils/AppContext";
import { BrowserRouter, Route, Switch, Link, useHistory } from "react-router-dom";
import NotFound from "pages/not-found";
import Order from "pages/order";
import history from "./utils/history";

const MainAuthContainer = lazy(() => import("components/authentication/MainAuthContainer"));
const OrderFreeMode = lazy(() => import("pages/orderfreemode"));

const Analytics = lazy(() => import("react-router-ga"));
const MainLandingPage = lazy(() => import("pages/landing/MainLandingPage"));

const Pricing = lazy(() => import("pages/pricing"));
const Purchasing = lazy(() => import("pages/purchasing"));
const TC = lazy(() => import("pages/tc"));
const SignUp = lazy(() => import("pages/signUp"));
const SignUpMVP = lazy(() => import("pages/signUpMVP"));
const FAQ = lazy(() => import("pages/faq"));
const About = lazy(() => import("pages/about"));
const Rebrand = lazy(() => import("pages/rebrand"));
const Maintenance = lazy(() => import("pages/maintenance"));
const FeatureLanding = lazy(() => import("pages/landing/FeatureLanding"));
const AlternateLandingPage = lazy(() => import("pages/landing/AlternateLandingPage"));
const Support = lazy(() => import("pages/support"));
const Feedback = lazy(() => import("pages/feedback"));

const renderLoader = () => <p></p>;

const RouteSwitch = () => {
    const history = useHistory();
    const context = useContext(AppContext);
    const [freeMode, setFreeMode] = useState(false);
    let isAuth = !context.state.isAuthenticated;
    let notdodge = context.state.isAuthenticated == true && context.state.iFrameSegmentFinished == true && context.state.accountType != "";
    return (
        <Suspense
            fallback={() => {
                renderLoader();
            }}
        >
            <>
                <React.Fragment> 
                    <BrowserRouter history={history}>
                        <Analytics id="UA-178929244-1">
                            {context.state.isAuthenticated && context.state.iFrameSegmentFinished && context.state.accountType == "" && (
                                <Switch>
                                    <Route exact path="/order" component = {props => <Order avd={false} {...props} key="order authenticated"/>}/>
                                    <Route exact path="/order_avd" component = {props => <Order avd={true} {...props} key="order_avd authenticated"/>}/>
                                    <Route
                                        component={() => {
                                            return (
                                                <Fragment>
                                                    {freeMode == false && (
                                                        <Fragment>
                                                            <h3
                                                                style={{
                                                                    fontWeight: "300",
                                                                }}
                                                            >
                                                                Oops! It looks like you have logged in with your Microsoft AAD account without setting up our product in your Azure Environment.
                                                                <br /> We are an Azure SaaS product, so you need to head over to{" "}
                                                                <a href="https://portal.azure.com/#create/cubesys.cloud_clarity" target="_blank">
                                                                    Create CloudClarity
                                                                </a>{" "}
                                                                and create the Cloud Clarity SaaS Resource in your Azure Tenant to start your free trial. <br /> <br />
                                                                If you just want to continue with our limited free version you can also{" "}
                                                                <Link
                                                                    onClick={() => {
                                                                        setFreeMode(true);
                                                                    }}
                                                                >
                                                                    setup free mode here
                                                                </Link>
                                                                .
                                                            </h3>
                                                            <button
                                                                className="ui button ui_button__component"
                                                                onClick={() => {
                                                                    context.logout();
                                                                }}
                                                            >
                                                                Logout
                                                            </button>
                                                        </Fragment>
                                                    )}
                                                    {freeMode == true && <OrderFreeMode />}
                                                </Fragment>
                                            );
                                        }}
                                    />
                                </Switch>
                            )}
                            {(notdodge || isAuth) && (
                                <Switch>
                                    {/* Factory Tools Pages */}
                                    {[
                                        "/dashboard",
                                        "/dashboard/resources",
                                        "/dashboard/tag-health",
                                        "/dashboard/suggestions",
                                        "/dashboard/costsummary",
                                        "/dashboard/cost-explorer",
                                        "/dashboard/AVD",

                                        "/tag-manager",
                                        "/tag-manager/cost",
                                        "/tag-manager/subscription/:subscriptionID",
                                        "/tag-manager/subscription/:subscriptionID/cost-summary",
                                        "/tag-manager/cost-picker",
                                        "/tag-manager/sub-picker",
                                        "/account/sub-configurer",
                                        "/avd",
                                        "/cost-explorer",
                                        "/tag-manager-preview"
                                    ].map((path, index) => (
                                        <Route path={path} component={MainAuthContainer} key={index} />
                                    ))}

                                    {/* Feature Pages */}
                                    {["/feature/tags", "/feature/dashboard", "/feature/tag-health", "/feature/cost-summary", "/feature/avd", "/avd-landing-1", "/avd-landing-2", "/avd-landing-3"].map((path, index) => (
                                        <Route key={index} exact path={path} component={FeatureLanding} />
                                    ))}

                                    {/* Alternate Landing Pages */}
                                    {["/home/tags", "/home/dashboard", "/home/governance", "/home/compliance"].map((path, index) => (
                                        <Route key={index} e exact path={path} component={AlternateLandingPage} />
                                    ))}

                                    <Route exact path="/" component={MainLandingPage} />
                                    <Route exact path="/maintenance" component={Maintenance} />
                                    <Route exact path="/order" component = {props => <Order avd={false} {...props} key="order"/>}/>
                                    <Route exact path="/order_avd" component = {props => <Order avd={true} {...props} key="order_avd"/>}/>
                                    <Route exact path="/purchasing" component={Purchasing} />
                                    <Route exact path="/pricing" component={Pricing} />
                                    <Route exact path="/sign-up" component={SignUp} />
                                    <Route exact path="/sign-up/MVP" component={SignUpMVP} />
                                    <Route exact path="/support" component={Support} />
                                    <Route exact path="/feedback" component={Feedback} />
                                    <Route exact path="/faq" component={FAQ} />
                                    <Route exact path="/legal" component={TC} />
                                    <Route exact path="/about" component={About} />
                                    <Route exact path="/rebrand" component={Rebrand} />
                                    <Route ecomponent={NotFound} />
                                </Switch>
                            )}
                        </Analytics>
                    </BrowserRouter>
                </React.Fragment>
            </>
        </Suspense>
    );
};

export default RouteSwitch;
