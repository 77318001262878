import React, { Component, Fragment } from "react";

import AppContext from "../../../utils/AppContext";
import { Link } from "react-router-dom";
import MobileMenuIcon from "components/page-layout/MobileMenuIcon";
import TableActionItem from "components/tag-manager/Components/TableActionItem";
import { Icon, Sidebar } from "semantic-ui-react";
import getStartedPdf from "./Getting_Started.pdf";
import HelpModal from "components/elements/HelpModal";
import "./TopMenu.css";
import LoginLoader from "./LoginLoader";
import { useHistory, withRouter } from "react-router-dom";
import DefaultTagFilterSelector from "./DefaultTagFilterSelector";
import { NotificationCenterHandle } from "../../common-plus/NotificationCenterHandle";

class TopMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            showNotifications: false,
            isHelpOpen: false,
            panelOpen: false,
        };
    }

    handleGettingStartedClick = () => {
        window.open(getStartedPdf);
    };

    handleFeedbackClick = () => {
        window.open("https://forms.office.com/Pages/ResponsePage.aspx?id=p3A5WMpPakapIFL7bTgLBBksa3JA7HRMlmXjrHRDZI5UQzlRNVRYQU0xMjc0QlhKRTNIQllUTU1EWiQlQCN0PWcu");
    };

    handleHelpClick = () => {
        if (this.state.isHelpOpen === false) {
            this.setState({
                isHelpOpen: true,
            });
        }
    };

    handleRequestDemoClick = () => {
        window.open("https://outlook.office365.com/owa/calendar/CloudClarity@cubesys.com.au/bookings/");
    };

    closeHelp = () => {
        if (this.state.isHelpOpen === true) {
            this.setState({
                isHelpOpen: false,
            });
        }
    };

    pageName(page) {
        switch (page) {
            case "":
                return <p></p>;
            case "avd-landing-1":
                return <p style={{ paddingLeft: "10px" }}> {" - AVD"} </p>;
            case "avd-landing-2":
                return <p style={{ paddingLeft: "10px" }}> {" - AVD"} </p>;
            case "avd-landing-3":
                return <p style={{ paddingLeft: "10px" }}> {" - AVD"} </p>;
            default:
                if (page.indexOf("feature") !== -1) {
                    return <p style={{ paddingLeft: "10px" }}> {this.capitaliseLast(page)} </p>;
                } else {
                    return <p style={{ paddingLeft: "10px" }}> {this.capitalise(page)} </p>;
                }
        }
    }

    capitalise(str) {
        let finalstr = str;
        if (str.indexOf("/") !== -1) {
            finalstr = finalstr.substring(0, str.indexOf("/"));
        }
        let returnablestr = " - " + finalstr;
        return returnablestr;
    }

    capitaliseLast(str) {
        let finalstr = str;
        let namesplit = str.split("/");
        let returnablestr = " - " + namesplit[1];
        return returnablestr;
    }

    togglePanel = () => {
        this.setState({
            panelOpen: !this.state.panelOpen,
        });
    };

    render() {
        return (
            <AppContext.Consumer>
                {(context) => (
                    <Fragment>
                        <div className="top-menu__component">
                            <div className="menu-logo-container">
                                <MobileMenuIcon />
                                <Link to="/" role="link" tabIndex={-1}>
                                    <img src={"/images/img_cloudclarity_logo.png"} className="cloudclarity-logo" alt="CloudClarity" />
                                    <img src={"/images/img_cloudclarity_logo-mobile.png"} className="cloudclarity-logo-mobile" alt="CloudClarity" />
                                </Link>
                                <div className="page-name">
                                    <span style={{ textTransform: "uppercase" }}>{this.pageName(window.location.pathname.substr(1))}</span>
                                </div>
                            </div>
                            <div className="user-menu">
                                {context.state.isAuthenticated == false && (
                                    <Fragment>
                                        <a className="hide-mobile" onClick={() => window.open("https://www.linkedin.com/company/cloudclarity")} title="CloudClarity LinkedIn" rel="nofollow">
                                            <TableActionItem actionName="Follow Us" icon="linkedin" title="Follow us on linkedin" />
                                        </a>
                                        <TableActionItem
                                            actionName="Request Demo"
                                            icon="request"
                                            title="Request a Demo"
                                            onClick={() => {
                                                this.handleRequestDemoClick();
                                            }}
                                        />

                                        <Link className="hide-mobile" to="/pricing" title="CloudClarity Pricing">
                                            <TableActionItem actionName="Pricing" icon="dollar sign" title="Pricing" />
                                        </Link>
                                        <TableActionItem actionName="Getting Started" title="Getting Started with CloudClarity" icon="compass" onClick={() => this.handleGettingStartedClick()} />
                                        <TableActionItem
                                            actionName="Subscribe"
                                            title="Subscribe"
                                            icon="microsoft"
                                            onClick={() => window.open("https://portal.azure.com/#create/cubesys.cloud_clarity")}
                                        />
                                    </Fragment>
                                )}
                                {context.state.isAuthenticated && (
                                    <Fragment>
                                        {/* <NotificationCenterHandle /> */}
                                        <Link to="/tag-manager/sub-picker" className="hide-mobile">
                                            <TableActionItem actionName="Tag Manager" title="Tag Manager - Subscription" icon="tag" />
                                        </Link>
                                        <Link to="/dashboard/tag-health" className="hide-mobile">
                                            <TableActionItem actionName="Tag Health" title="Tag Health" icon="dashboard" />
                                        </Link>
                                        <Link to="/dashboard" className="hide-mobile">
                                            <TableActionItem actionName="Cost Summary​" title="Cost Summary​" icon="chart line" />
                                        </Link>

                                        {/* only show the avd link if the user is allowed to see/use the AVD feature */}
                                        {context.state.avdAvailable === true &&
                                            <Link to="/avd" className="hide-mobile">
                                                <TableActionItem actionName="AVD" title="AVD" icon="desktop" />
                                            </Link>
                                        }
                                    </Fragment>
                                )}
                                {context.isAuthenticated === null && <p>loading..</p>}

                                {context.state.isAuthenticated === false && context.state.isLoading === false && (
                                    <div className="acount-info">
                                        <TableActionItem
                                            actionName="Log in"
                                            icon="login"
                                            title="Login"
                                            onClick={() => {
                                                context.tryLogin();
                                            }}
                                        />
                                    </div>
                                )}
                                {context.state.isAuthenticated === true && context.state.isLoading == false && (
                                    <div
                                        className="acount-info"
                                        onClick={() => {
                                            this.togglePanel();
                                        }}
                                        style={{ cursor: "pointer" }}
                                    >
                                        <Icon name="user circle" style={{ fontSize: "20px" }}></Icon>
                                        <div className="user-details">
                                            &#160;&#160;
                                            {context.state.userEmail}
                                        </div>
                                    </div>
                                )}
                                {context.state.isLoading && <LoginLoader />}
                            </div>
                        </div>
                        <Sidebar className="account-sidebar" animation="overlay" icon="labeled" direction="right" inverted onHide={() => this.togglePanel()} vertical visible={this.state.panelOpen}>
                            <div
                                style={{
                                    height: "100vh",
                                    backgroundColor: "#202025",
                                }}
                            >
                                <div className="sidebar-content">
                                    <h3>Account Information</h3>
                                    <h4>User Details</h4>
                                    <p className="text grey">
                                        <span
                                            style={{
                                                fontWeight: "bold",
                                                display: "flex",
                                            }}
                                        >
                                            <Icon name="envelope outline" style={{ fontSize: "18px" }}></Icon>
                                            Account Email:
                                        </span>{" "}
                                        {this.context.state.userEmail}
                                    </p>
                                    <p className="text grey">
                                        <span
                                            style={{
                                                fontWeight: "bold",
                                                display: "flex",
                                            }}
                                        >
                                            <Icon name="user circle" style={{ fontSize: "18px" }}></Icon>
                                            User Name:
                                        </span>{" "}
                                        {this.context.state.userName}
                                    </p>
                                    <p>Account User Name and Password are determined by your Microsoft Entra account in Azure.</p>
                                    <h4>Subscription Details</h4>
                                    <p className="text grey">
                                        <span
                                            style={{
                                                fontWeight: "bold",
                                                display: "flex",
                                            }}
                                        >
                                            <Icon name="universal access" style={{ fontSize: "18px" }}></Icon>
                                            Account Tier:
                                        </span>{" "}
                                        {this.context.state.accountType}
                                    </p>
                                    <p className="text grey">
                                        <span
                                            style={{
                                                fontWeight: "bold",
                                                display: "flex",
                                            }}
                                        >
                                            <Icon name="clock outline" style={{ fontSize: "18px" }}></Icon>
                                            Subscription Status:
                                        </span>{" "}
                                        {this.context.state.expiryDate}
                                    </p>
                                    <p className="text grey">
                                        <span
                                            style={{
                                                fontWeight: "bold",
                                                display: "flex",
                                            }}
                                        >
                                            <Icon name="tag" />
                                            Default Evaluated Tag Keys
                                        </span>{" "}
                                        <DefaultTagFilterSelector />
                                    </p>
                                    <p className="text grey">
                                        <span
                                            style={{
                                                fontWeight: "bold",
                                                display: "flex",
                                            }}
                                        >
                                            <Icon name="tasks" style={{ fontSize: "18px" }}></Icon>
                                            Elevated Subscriptions:
                                        </span>{" "}
                                        <ul
                                            style={{
                                                padding: "0",
                                                fontSize: "10px",
                                            }}
                                        >
                                            {this.context.state.elevatedSubscriptionNames &&
                                                this.context.state.elevatedSubscriptionNames.map((sub, index) => {
                                                    return <li key={`elevated-sub-${sub}`}>{sub}</li>;
                                                })}
                                        </ul>
                                    </p>
                                    <div>
                                        <Link to="/account/sub-configurer">
                                            <button className="ui blue basic inverted button">Configure Access</button>
                                        </Link>
                                    </div>
                                    <p>
                                        If you would like to renew your CloudClarity subscription, change your account Tier you can do so inside Azure on the SaaS component. If you have any further
                                        questions please get in touch with our team at <a href="mailto:info@cloudclarity.app">info@cloudclarity.app</a>
                                    </p>
                                    <hr />
                                    <TableActionItem
                                        actionName="Logout"
                                        icon="login"
                                        title="Logout"
                                        onClick={() => {
                                            this.props.history.push("/");
                                            context.logout();
                                        }}
                                    />
                                </div>
                            </div>
                        </Sidebar>
                    </Fragment>
                )}
            </AppContext.Consumer>
        );
    }
}
TopMenu.contextType = AppContext;
export default withRouter(TopMenu);
