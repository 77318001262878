import React, {useContext} from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import AppContext from "../../../utils/AppContext";
import TopMenu from "components/page-layout/TopMenu";
import moment from "moment";
import "./CommonPageLayout.css";

const CommonPageLayout = ({className, pageName, fullwidth, children, activeTab}) => {
	const context = useContext(AppContext);
	return (
		<>
			{context.state.accountType == "Free" && (
				<div className="freebar">
					<p>
						You are currently using the free version of CloudClarity. Click <Link to={"/pricing"}>here</Link> to upgrade
					</p>
				</div>
			)}

			{context.state.elevatedSubscriptions !== undefined && context.state.elevatedSubscriptions.length !== 0 && context.state.accountType == "Trial" && (
				<div className="freebar">
					<p>You are currently using the trial version of CloudClarity. Your Trial will expire {moment(context.state.expiryDate, "MM/DD/YYYY hh:mm:ss A").fromNow()}</p>
				</div>
			)}
			
			<div className={`common-page-layout__component${className ? ` ${className}` : ""}`}>
				<div className="top-menu-container">
					<TopMenu activeTab={activeTab} />
				</div>

				<div className={fullwidth ? "content-area full-width" : "content-area"}>{children}</div>
				<div className="footer-spacer"></div>
			</div>
		</>
	);
};

CommonPageLayout.propTypes = {
	pageName: PropTypes.string,
	children: PropTypes.object.isRequired
};

CommonPageLayout.defaultProps = {
	pageName: null
};

export default CommonPageLayout;
